<template>
  <div class="container">
    <div class="charts">
      <line-chart
        v-if="chartType === 'line'"
        :chartData="chartData"
        :colors="colors"
      />
      <clock-chart
        v-else-if="chartType == 'clockBar'"
        :chartData="chartData"
        :colors="colors"
        :showXAxis="true"
      />
      <bar-chart
        v-else
        :chartData="chartData"
        :colors="colors"
        :showXAxis="true"
        :isShapeCircle="isShapeCircle"
      />
    </div>

    <modal :isOpen="isReportModalOpen" @close="$emit('close', false)">
      <div class="file-buttons">
        <custom-button
          variant="button"
          download="reporte.csv"
          :href="downloadLink"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button variant="button" @click="downloadAsPDF">
          PDF
          <font-awesome-icon :icon="icons.pdf" slot="icon" />
        </custom-button>
      </div>

      <div ref="printMe">
        <template slot="title">Reporte</template>
        <div class="report-title">
          <h1>Reporte Clima Laboral</h1>
        </div>

        <div class="report-filters">
          <h1>Filtros seleccionados:</h1>
          <div>
            <template v-for="key in Object.keys(filters)"
              >/
              <p :key="key + 'p'">{{ " " + key + ":" }}</p>
              <div :key="key + 'div'">
                <p v-for="(filter, index) in filters[key]" :key="filter">
                  {{ filter + (index == filters[key].length - 1 ? "" : ",") }}
                </p>
              </div>
            </template>
            <template v-if="Object.keys(selectedDimensions).length">
              /
              <p>{{ " " + "Dimension" + ":" }}</p>
              <div>
                <p
                  v-for="(key, index) in Object.keys(selectedDimensions)"
                  :key="key"
                >
                  {{
                    selectedDimensions[key] +
                      (index == Object.keys(selectedDimensions).length - 1
                        ? ""
                        : ",")
                  }}
                </p>
              </div>
              /
            </template>
          </div>
        </div>

        <div class="report-data-table">
          <div class="header row">
            <template>
              <span><p>Participación #</p></span>
              <span><p>Participación %</p></span>
              <span><p>Resultado General</p></span>
              <span><p>Dimensiones</p></span>
              <span><p>Preguntas</p></span>
            </template>
          </div>

          <div class="row">
            <span
              ><p>{{ participation[1] }}</p></span
            >
            <span
              ><p>{{ participation[0] }}%</p></span
            >
            <span
              :class="
                satisfactionIndex[0] <= limits[0]
                  ? 'limit-bad'
                  : satisfactionIndex[0] <= limits[1]
                  ? 'limit-medium'
                  : 'limit-good'
              "
            >
              <p>{{ satisfactionIndex[0] }}%</p>
            </span>
            <span>
              <p>{{ survey.sections.length }}</p>
            </span>
            <span>
              <p>{{ survey.questions.length }}</p>
            </span>
          </div>
          <div><span></span></div>
        </div>

        <div class="report-table" :style="tableLengthStyle">
          <div class="header row">
            <template v-if="selectedDisplay === 'Análisis de Resultados'">
              <span>{{ selectedCategory }}</span>
              <span><p>Fortaleza</p></span>
              <span><p>Oportunidad</p></span>
            </template>
            <template v-else>
              <span>{{ selectedCategory }}</span>
              <span
                v-for="scale in survey.scale.map(scale => scale.text)"
                :key="scale"
              >
                <p>{{ scale }}</p></span
              >
            </template>
          </div>
          <div
            class="row"
            v-for="category in Object.keys(chartData.dataset).sort((a, b) =>
              parseInt(chartData.dataset[a][0]) >
              parseInt(chartData.dataset[b][0])
                ? -1
                : parseInt(chartData.dataset[b][0]) >
                  parseInt(chartData.dataset[a][0])
                ? 1
                : 0
            )"
            :key="category"
          >
            <span>
              <p>{{ category }}</p>
            </span>
            <span
              v-for="(option, index) in chartData.dataset[category]"
              :key="option + category + index"
              :class="
                chartData.legend[index] === 'Fortaleza'
                  ? option <= limits[0]
                    ? 'limit-bad'
                    : option <= limits[1]
                    ? 'limit-medium'
                    : 'limit-good'
                  : ''
              "
              >{{ option }}%</span
            >
          </div>
          <div><span></span></div>
        </div>
      </div>
    </modal>

    <modal :isOpen="isOpenAnswersModalOpen" @close="$emit('close', false)">
      <div class="file-buttons">
        <custom-button
          variant="button"
          download="respuestas_abiertas.csv"
          :href="downloadLinkOA"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button variant="button" @click="downloadAsPDFOA">
          PDF
          <font-awesome-icon :icon="icons.pdf" slot="icon" />
        </custom-button>
      </div>

      <div>
        <template slot="title">Respuestas abiertas</template>
        <div class="openAnswers-title">
          <h1>{{ selectedCategory }}</h1>
        </div>
        <div class="report-filters">
          <h1>Filtros seleccionados:</h1>
          <div>
            <template v-for="key in Object.keys(filters)"
              >/
              <p :key="key + 'p'">{{ " " + key + ":" }}</p>
              <div :key="key + 'div'">
                <p v-for="(filter, index) in filters[key]" :key="filter">
                  {{ filter + (index == filters[key].length - 1 ? "" : ",") }}
                </p>
              </div>
            </template>
            <template v-if="Object.keys(selectedDimensions).length">
              /
              <p>{{ " " + "Dimension" + ":" }}</p>
              <div>
                <p
                  v-for="(key, index) in Object.keys(selectedDimensions)"
                  :key="key"
                >
                  {{
                    selectedDimensions[key] +
                      (index == Object.keys(selectedDimensions).length - 1
                        ? ""
                        : ",")
                  }}
                </p>
              </div>
              /
            </template>
          </div>
        </div>

        <template>
          <div
            v-if="selectedCategory === 'Pregunta'"
            class="openAnswers-content"
          >
            <template v-for="key in Object.keys(openAnswersData)">
              <div
                class="openAnswers-section"
                v-if="openAnswersData[key].length"
                :key="key"
              >
                <div class="openAnswers-category">{{ key }}</div>
                <div
                  class="openAnswers-answer"
                  v-for="(answer, index) in openAnswersData[key]"
                  :key="answer + key + index"
                >
                  {{ answer.answer }}
                </div>
              </div>
            </template>
          </div>

          <div v-else class="openAnswers-content">
            <template v-for="key in Object.keys(openAnswersData)">
              <div class="openAnswers-section" :key="key">
                <div class="openAnswers-category">{{ key }}</div>
                <template v-for="key2 in Object.keys(openAnswersData[key])">
                  <div
                    :key="key2 + key"
                    v-if="openAnswersData[key][key2].length"
                  >
                    <div class="openAnswers-category">
                      {{ key2 }}
                    </div>
                    <div
                      class="openAnswers-answer"
                      v-for="(answer, index) in openAnswersData[key][key2]"
                      :key="key + key2 + index"
                    >
                      {{ answer }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </div>

      <vue-html2pdf
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        :pdf-quality="2"
        pdf-content-width="90%"
        :htmlToPdfOptions="{
          margin: [5, 20, 0, 0],
          filename: `respuestas_abiertas.pdf`
        }"
        ref="printMeOA"
      >
        <section slot="pdf-content">
          <div>
            <template slot="title">Respuestas abiertas</template>
            <div class="openAnswers-title">
              <h1>{{ selectedCategory }}</h1>
            </div>
            <div class="report-filters">
              <h1>Filtros seleccionados:</h1>
              <div>
                <template v-for="key in Object.keys(filters)"
                  >/
                  <p :key="key + 'p'">{{ " " + key + ":" }}</p>
                  <div :key="key + 'div'">
                    <p v-for="(filter, index) in filters[key]" :key="filter">
                      {{
                        filter + (index == filters[key].length - 1 ? "" : ",")
                      }}
                    </p>
                  </div>
                </template>
                <template v-if="Object.keys(selectedDimensions).length">
                  /
                  <p>{{ " " + "Dimension" + ":" }}</p>
                  <div>
                    <p
                      v-for="(key, index) in Object.keys(selectedDimensions)"
                      :key="key"
                    >
                      {{
                        selectedDimensions[key] +
                          (index == Object.keys(selectedDimensions).length - 1
                            ? ""
                            : ",")
                      }}
                    </p>
                  </div>
                  /
                </template>
              </div>
            </div>

            <template>
              <div
                v-if="selectedCategory === 'Pregunta'"
                class="openAnswers-content"
              >
                <template v-for="key in Object.keys(openAnswersData)">
                  <div
                    class="openAnswers-section"
                    v-if="openAnswersData[key].length"
                    :key="key"
                  >
                    <div class="openAnswers-category">{{ key }}</div>
                    <div
                      class="openAnswers-answer"
                      v-for="(answer, index) in openAnswersData[key]"
                      :key="answer + key + index"
                    >
                      {{ answer.answer }}
                    </div>
                  </div>
                </template>
              </div>

              <div v-else class="openAnswers-content">
                <template v-for="key in Object.keys(openAnswersData)">
                  <div class="openAnswers-section" :key="key">
                    <div class="openAnswers-category">{{ key }}</div>
                    <template v-for="key2 in Object.keys(openAnswersData[key])">
                      <div
                        :key="key2 + key"
                        v-if="openAnswersData[key][key2].length"
                      >
                        <div class="openAnswers-category">
                          {{ key2 }}
                        </div>
                        <div
                          class="openAnswers-answer"
                          v-for="(answer, index) in openAnswersData[key][key2]"
                          :key="key + key2 + index"
                        >
                          {{ answer }}
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </section>
      </vue-html2pdf>
    </modal>
  </div>
</template>

<script>
import jspdf from "jspdf";
import Modal from "../../components/Modal.vue";
import BarChart from "../charts/BarChart.vue";
import LineChart from "../charts/LineChart.vue";
import ClockChart from "../charts/ClockChart.vue";
import CustomButton from "../CustomButton.vue";
import {
  faFileDownload,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint
} from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    BarChart,
    LineChart,
    ClockChart,
    Modal,
    CustomButton
  },
  data() {
    return {
      icons: {
        fileDownload: faFileDownload,
        excel: faFileExcel,
        pdf: faFilePdf,
        powerpoint: faFilePowerpoint
      }
    };
  },

  props: {
    chartType: {
      type: String,
      default: "",
      required: false
    },
    selectedCategory: {
      type: String,
      required: true
    },
    // categories: {
    //   type: Array,
    //   required: true,
    // },
    questions: {
      type: Array,
      required: true
    },
    selectedDisplay: {
      type: String,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    isReportModalOpen: {
      type: Boolean,
      required: true
    },
    isOpenAnswersModalOpen: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    participation: {
      type: Array,
      required: true
    },
    satisfactionIndex: {
      type: Array,
      required: true
    },
    limits: {
      type: Array,
      required: true
    },
    selectedDimensions: {
      type: Object,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    openAnswersData: {
      required: true
    }
  },

  mounted() {},

  methods: {
    downloadAsPDF() {
      const el = this.$refs.printMe;
      this.$html2canvas(el).then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jspdf("p", "pt", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const pdf2 = new jspdf("p", "pt", [
          pdf.internal.pageSize.getWidth(),
          pdfHeight + 20 > pdf.internal.pageSize.getHeight()
            ? pdfHeight + 20
            : pdf.internal.pageSize.getHeight()
        ]);
        pdf2.addImage(
          imgData,
          "PNG",
          (pdf2.internal.pageSize.getWidth() - pdfWidth) / 2,
          10,
          pdfWidth,
          pdfHeight
        );
        pdf2.save("reporte.pdf");
      });
    },

    downloadAsPDFOA() {
      // const logo = this.$refs.logo;
      // logo.setAttribute("color", this.colors[1]);
      // logo.setAttribute("width", logo.getBoundingClientRect().width);
      // logo.setAttribute("height", logo.getBoundingClientRect().height);
      // let element = this.$refs.printMeOA.$el;
      // const svgElements = Array.from(element.querySelectorAll("svg"));
      // svgElements.forEach(s => {
      //   s.setAttribute("color", "#15B195");
      //   s.setAttribute("fill", "#15B195");
      // });

      this.$refs.printMeOA.generatePdf({ margin: 10 });
      // const el = this.$refs.printMeOA;
      // const logo = this.$refs.logo;
      // logo.setAttribute("width", logo.getBoundingClientRect().width);
      // logo.setAttribute("height", logo.getBoundingClientRect().height);
      // logo.setAttribute("color", this.colors[1]);

      // logo.style.width = null;
      // logo.style.height = null;
      // this.$html2canvas(el).then(canvas => {
      //   const imgData = canvas.toDataURL("image/png");
      //   const pdf = new jspdf("p", "pt", "a4");
      //   const imgProps = pdf.getImageProperties(imgData);
      //   const pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
      //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      //   const pdf2 = new jspdf("p", "pt", [
      //     pdf.internal.pageSize.getWidth(),
      //     pdfHeight + 20
      //   ]);
      //   pdf2.addImage(
      //     imgData,
      //     "PNG",
      //     (pdf2.internal.pageSize.getWidth() - pdfWidth) / 2,
      //     10,
      //     pdfWidth,
      //     pdfHeight
      //   );
      //   pdf2.save("respuestas_abiertas.pdf");
      // });
    },

    CSVtoArray(text) {
      // var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      // var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // // Return NULL if input string is not well formed CSV string.
      // if (!re_valid.test(text)) return null;
      // var a = []; // Initialize array to receive values.
      // text.replace(
      //   re_value, // "Walk" the string using replace with callback.
      //   function(m0, m1, m2, m3) {
      //     // Remove backslash from \' in single quoted values.
      //     if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      //     // Remove backslash from \" in double quoted values.
      //     else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      //     else if (m3 !== undefined) a.push(m3);
      //     return ""; // Return empty string.
      //   }
      // );
      // // Handle special case of empty last value.
      // if (/,\s*$/.test(text)) a.push("");
      // return a.join(" ");
      return text.replace(/,/g, "").replace(/(\r\n|\n|\r)/gm, "");
    },

    getReportCSV() {
      let csv = [];
      csv.push([
        "Participación #",
        "Participación %",
        "Resultado General",
        "Dimensiones",
        "Preguntas"
      ]);

      csv.push(
        "\n" + this.participation[1],
        this.participation[0] + "%",
        this.satisfactionIndex[0] + "%",
        this.survey.sections.length,
        this.survey.questions.length
      );

      if (this.selectedDisplay === "Análisis de Resultados") {
        csv.push(["\n \n" + this.selectedCategory, "Fortaleza", "Oportunidad"]);
        let temp = [];
        Object.keys(this.chartData.dataset)
          .sort((a, b) =>
            parseInt(this.chartData.dataset[a][0]) >
            parseInt(this.chartData.dataset[b][0])
              ? -1
              : parseInt(this.chartData.dataset[b][0]) >
                parseInt(this.chartData.dataset[a][0])
              ? 1
              : 0
          )
          .forEach(category => {
            temp.push(
              "\n" + this.CSVtoArray(category),
              ...this.chartData.dataset[category].map(option => option + "%")
            );
          });
        csv.push(temp);
      } else {
        csv.push([
          "\n \n" + this.selectedCategory,
          ...this.survey.scale.map(scale => this.CSVtoArray(scale.text))
        ]);
        let temp = [];
        Object.keys(this.chartData.dataset)
          .sort((a, b) =>
            parseInt(this.chartData.dataset[a][0]) >
            parseInt(this.chartData.dataset[b][0])
              ? -1
              : parseInt(this.chartData.dataset[b][0]) >
                parseInt(this.chartData.dataset[a][0])
              ? 1
              : 0
          )
          .forEach(category => {
            temp.push(
              "\n" + this.CSVtoArray(category),
              ...this.chartData.dataset[category].map(option => option + "%")
            );
          });
        csv.push(temp);
      }

      return csv;
    },

    getReportCSVOA() {
      let csv = [];

      if (this.selectedCategory === "Pregunta") {
        let temp = [];
        Object.keys(this.openAnswersData).forEach(key => {
          if (this.openAnswersData[key].length) {
            temp.push(this.CSVtoArray(key));
            this.openAnswersData[key].forEach(answer => {
              temp.push("\n" + this.CSVtoArray(answer.answer));
            });
          }
        });
        csv.push(temp);
      } else {
        let temp = [];
        Object.keys(this.openAnswersData).forEach((key, cont) => {
          if (cont === 0) temp.push(this.CSVtoArray(key));
          else temp.push("\n" + this.CSVtoArray(key));
          Object.keys(this.openAnswersData[key]).forEach(key2 => {
            if (this.openAnswersData[key][key2].length) {
              temp.push("\n" + this.CSVtoArray(key2));
              this.openAnswersData[key][key2].forEach(answer => {
                temp.push("\n" + this.CSVtoArray(answer));
              });
            }
          });
        });
        csv.push(temp);
      }
      return csv;
    }
  },

  computed: {
    tableLengthStyle() {
      let header = [];
      if (this.selectedDisplay === "Análisis de Resultados") {
        header = [this.selectedCategory, "Fortaleza", "Oportunidad"];
      } else {
        header = [this.selectedCategory, ...this.survey.scale];
      }
      return { "--header-length": header.length };
    },

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    downloadLinkOA() {
      let csvFileData = this.getReportCSVOA();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    isShapeCircle() {
      return this.chartType === "roundBar";
    }

    // chartData() {
    //   let datasetAndLegend;
    //   if (this.selectedDisplay === "Análisis de Resultados") {
    //     switch (this.selectedCategory) {
    //       case "Dimension":
    //         datasetAndLegend = this.getSatisfactionIndexByDimensions();
    //         break;
    //       case "Pregunta":
    //         datasetAndLegend = this.getSatisfactionIndexByQuestions();
    //         break;
    //       default:
    //         datasetAndLegend = this.getSatisfactionIndexByCategories();
    //         break;
    //     }
    //   } else {
    //     switch (this.selectedCategory) {
    //       case "Dimension":
    //         datasetAndLegend = this.getAnswersOfOptionByDimensions();
    //         break;
    //       case "Pregunta":
    //         datasetAndLegend = this.getAnswersOfOptionByQuestions();
    //         break;
    //       default:
    //         datasetAndLegend = this.getAnswersOfOptionByCategories();
    //         break;
    //     }
    //   }
    //   return {
    //     dataset: datasetAndLegend.dataset,
    //     legend: datasetAndLegend.legend,
    //   };
    // },
  }
};
</script>

<style scoped>
.charts * {
  font-weight: var(--weight-semi-bold);
}

.container {
  height: 100%;
  font-size: 16px;
}

.charts {
  height: 100%;
  width: 100%;
}

.report-table {
  margin: auto;
}

.row {
  display: grid;
  grid-template-columns: repeat(var(--header-length), 1fr);
  border-right: 1px solid var(--grayColor2);
  border-left: 1px solid var(--grayColor2);
  border-bottom: 1px solid var(--grayColor2);
  font-size: 0.8rem;
}

.report-data-table .row {
  grid-template-columns: repeat(5, 1fr);
}

.header.row {
  border-top: 1px solid var(--grayColor2);
  border-top-left-radius: var(--lengthSm2);
  border-top-right-radius: var(--lengthSm2);
  color: white;
  color: var(--fontColor3);
  font-weight: var(--semi-bold);
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.row span {
  padding: var(--lengthSm3);
  font-weight: var(--medium);
}

.row span:not(:last-child) {
  border-right: 1px solid var(--grayColor2);
}

.report-filters {
  text-align: center;
  margin-bottom: var(--lengthMd2);
  margin-top: var(--lengthSm2);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.report-filters > div {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: center;
  min-width: 95%;
  max-width: 95%;
  flex-wrap: wrap;
}

.report-filters h1 {
  font-size: 1rem;
  font-weight: bold;
}

.report-filters p {
  font-size: 0.85rem;
}

.report-filters > div > div > p {
  font-weight: bold;
}

.report-filters div {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.limit-bad {
  color: #fd7670;
}

.limit-medium {
  color: #f3c319;
}

.limit-good {
  color: #15b195;
}

h1 {
  font-size: 1.4em;
}

.report-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.report-title .logo-container {
  height: 1.8em;
  position: absolute;
  right: 1em;
  top: 0;
}

.report-title * {
  font-size: 1.2rem;
}

.report-data-table .row > span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.report-table .row > span {
  display: flex;
  align-items: center;
}

.report-table .row > span:not(:first-child) {
  text-align: center;
  justify-content: center;
  text-align: center;
}

.openAnswers-title {
  text-align: center;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.openAnswers-title .logo-container {
  height: 1.8em;
  position: absolute;
  right: 1em;
  top: 0;
}

.openAnswers-title * {
  font-size: 1.2rem;
}

.openAnswers-category {
  font-size: 0.92rem;
  color: var(--fontColor1);
  font-weight: 600;
  margin-bottom: 0.8em;
}

.openAnswers-section {
  margin-bottom: 1.2em;
}

.openAnswers-answer {
  font-size: 0.875rem;
  margin-bottom: 0.6em;
  border: solid 2px #f3f3f4;
  border-radius: 8px;
  padding: 1em;
  background-color: #fafafa;
}

.file-buttons {
  display: flex;
  gap: 1em;
}

/* LOGO CONTAINER */
.logo-container {
  grid-area: logo;
  margin: 0.5em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo-container svg {
  height: 100%;
}

.logo-container .cls-1 {
  fill: var(--primary-selected-color);
}
.logo-container .cls-2,
.logo-container .cls-3 {
  fill: #3d3f3e;
}
.logo-container .cls-2 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

@media (max-height: 1000px) {
  .container {
    font-size: 16px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}
</style>
