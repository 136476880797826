<template>
  <div
    class="dashboard"
    :style="dataStyles"
    v-if="
      categories &&
        selectedSurvey &&
        selectedCategory &&
        dataComplete &&
        !loading
    "
    ref="printMe"
  >
    <div
      class="dashboard-container"
      :class="{ confidential: isConfidential }"
      v-if="Object.keys(selectedSurvey).length"
    >
      <div class="tools-container">
        <div class="title">
          <h1>Dashboard</h1>
          <h3>Resultados</h3>
        </div>

        <div class="tools-buttons">
          <template
            v-for="index in selectedDisplay == 'Análisis de Resultados'
              ? 2
              : selectedSurvey.scale.length"
          >
            <div :key="index">
              <palette
                :startColor="selectedSurvey.colors[index - 1] || '#15B195'"
                @changeColor="(color) => changeColor(color, index - 1)"
              />
            </div>
          </template>

          <div>
            <chart-select
              v-model="chartType"
              :charts="['squareBar', 'roundBar', 'line', 'clockBar']"
            />
          </div>

          <div>
            <limit-select @click="setEditLimitOptionsModal" />
          </div>

          <collapse label="Información">
            <h4 slot="tittle">Escala</h4>
            <div slot="content">
              <h4>Fortaleza</h4>
              <div
                v-for="(scale, index) in selectedSurvey.scale.filter(
                  (el) => el.isPositive,
                )"
                :key="index + scale"
                class="tag"
              >
                <h5>{{ scale.text }}</h5>
              </div>
              <h4>Oportunidad</h4>
              <div
                slot="content2"
                v-for="(scale, index) in selectedSurvey.scale.filter(
                  (el) => !el.isPositive,
                )"
                :key="index + scale + 2"
                class="tag"
              >
                <h5>{{ scale.text }}</h5>
              </div>
            </div>

            <h4 slot="tittle2">Participacion</h4>
            <div slot="content2">
              <h4>Digital</h4>
              <p>
                {{
                  (
                    ((filteredAnswersLength - totalWrittenAnswers) /
                      filteredAnswersLength) *
                    100
                  ).toFixed(0)
                }}%
              </p>
              <h4>No digital</h4>
              <p>
                {{
                  ((totalWrittenAnswers / filteredAnswersLength) * 100).toFixed(
                    0,
                  )
                }}%
              </p>
            </div>

            <h4 slot="tittle3">Datos</h4>
            <div slot="content3">
              <h4>Periodos</h4>
              <p>
                {{ surveys.length }}
              </p>
              <h4>Escala</h4>
              <p>
                {{ selectedSurvey.scale.length }}
              </p>
              <h4>Dimensiones</h4>
              <p>
                {{ selectedSurvey.sections.length }}
              </p>
              <h4>Preguntas</h4>
              <p>
                {{ selectedSurvey.questions.length }}
              </p>
            </div>
          </collapse>

          <custom-button variant="secondary" @click="downloadPPT">
            <font-awesome-icon :icon="icons.powerpoint" slot="icon" />
            PPT
          </custom-button>

          <custom-button
            variant="secondary"
            :to="{
              name: 'GeneralReport',
              params: { surveyName: selectedSurvey.name },
            }"
            target="_blank"
          >
            Reporte
          </custom-button>

          <period-select
            :surveys="
              [...surveys].sort((a, b) =>
                a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
              )
            "
            :selectedSurveyName="selectedSurvey.name"
            @input="changeSurvey"
          />

          <custom-button variant="button" @click="isModalOpen = true">
            <font-awesome-icon :icon="icons.filter" slot="icon" />
            Filtrar
          </custom-button>
        </div>
      </div>

      <div class="tags-container">
        <tag-bar :filters="filters" :selectedDimensions="selectedDimensions" />
      </div>

      <template v-if="!isConfidential">
        <div class="participation-container">
          <participation-chart
            ref="participationChart"
            :filters="filters"
            :participation="participation[1]"
            :totalParticipation="participation[0]"
            :totalWrittenAnswers="totalWrittenAnswers"
            :surveyId="selectedSurvey.id"
            :participationData="participationData"
            :selectedCategory="selectedParticipationCategory"
            @change="
              (e) => {
                selectedParticipationCategory = e;
                setData().then(openParticipation);
              }
            "
          />
        </div>

        <div class="doughnut-chart-container">
          <doughnut-chart-satisfaction
            :scale="selectedSurvey.scale"
            :colors="selectedSurvey.colors"
            :satisfactionIndexOfCompany="satisfactionIndexOfCompany"
          />
        </div>

        <div class="engagement-chart-container">
          <doughnut-chart-engagement
            :survey="selectedSurvey"
            :scale="selectedSurvey.scale"
            :colors="selectedSurvey.colors"
            :filters="filters"
            :participation="participation"
            :satisfactionIndex="satisfactionIndexOfCompany"
            :limits="selectedSurvey.limits ? selectedSurvey.limits : [60, 80]"
            :selectedCategory="selectedCategory"
            :selectedDimensions="selectedDimensions"
            :satisfactionIndexByTitle="engagementData.satisfactionIndexByTitle"
            :satisfactionIndexOfCompany="engagementData.satisfactionIndex"
          />
        </div>

        <div class="ENPS-chart-container">
          <doughnut-chart-ENPS
            :survey="selectedSurvey"
            :colors="selectedSurvey.colors"
            :filters="filters"
            :questions="selectedSurvey.questions"
            :participation="participation"
            :satisfactionIndex="satisfactionIndexOfCompany"
            :selectedDimensions="selectedDimensions"
            :eNPS="eNPSData.eNPS"
            :eNPSPerAnswer="eNPSData.eNPSPerAnswer"
            :eNPSSatisfactionIndex="eNPSData.eNPSSatisfactionIndex"
            :filteredAnswersLength="filteredAnswersLength"
          />
        </div>

        <div class="main-chart-container">
          <div class="main-chart-text">
            <div class="main-chart-display">
              <custom-select
                :options="['Análisis de Resultados', 'Respuestas por Opción']"
                @input="setDashboardData"
                v-model="selectedDisplay"
              ></custom-select>
              <h3>{{ selectedCategory }}</h3>
            </div>
            <div>
              <div class="display-btn" @click="isOpenAnswersModalOpen = true">
                <font-awesome-icon :icon="icons.openAnswers" slot="icon" />
              </div>
              <div class="display-btn" @click="isReportModalOpen = true">
                <font-awesome-icon :icon="icons.plusCircle" slot="icon" />
              </div>
            </div>
          </div>

          <div class="main-chart" ref="mainChart">
            <main-chart
              ref="mainChartComponent"
              :chartData="chartData"
              :selectedCategory="selectedCategory"
              :categories="categories"
              :survey="selectedSurvey"
              :selectedDisplay="selectedDisplay"
              :colors="selectedSurvey.colors"
              :chartType="chartType"
              :questions="selectedSurvey.questions"
              :isReportModalOpen="isReportModalOpen"
              :isOpenAnswersModalOpen="isOpenAnswersModalOpen"
              :filters="filters"
              :participation="participation"
              :satisfactionIndex="satisfactionIndexOfCompany"
              :limits="selectedSurvey.limits ? selectedSurvey.limits : [60, 80]"
              :selectedDimensions="selectedDimensions"
              :openAnswersData="openAnswersData"
              @close="
                isReportModalOpen = false;
                isOpenAnswersModalOpen = false;
              "
            ></main-chart>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="empty-state-confidential">
          <font-awesome-icon :icon="icons.confidential" slot="icon" />
          <p>
            La plataforma te permite visualizar resultados a partir de grupos de
            {{ selectedSurvey.minRequire || 4 }}
            personas
          </p>
        </div>
      </template>
    </div>

    <modal :isOpen="isModalOpen" @close="isModalOpen = false">
      <template slot="title">Filtrar</template>
      <div class="filters-container">
        <filter-box
          :startCategory="selectedCategory || ''"
          :startDimensions="selectedDimensions || {}"
          :startFilters="filters || {}"
          v-on:editFilters="handleEditFilter"
          :categories="[
            ...categories.slice(1, categories.length),
            {
              name: 'Dimension',
              options: selectedSurvey.sections.filter(
                (section) =>
                  section.id !== 'engagement' && section.id !== 'enps',
              ),
            },
            categories[0],
          ]"
          :scale="selectedSurvey.scale"
          v-on:closeModal="isModalOpen = false"
        ></filter-box>
      </div>
    </modal>

    <modal :isOpen="isLimitModalOpen" @close="isLimitModalOpen = false">
      <template slot="title">Limite</template>
      <validation-observer tag="div" v-slot="{ handleSubmit, invalid }">
        <form
          @submit.prevent="handleSubmit(editSurveyLimits)"
          class="surveyLimitsForm"
        >
          <custom-input
            :label="'Critico'"
            type="number"
            v-model="editLimitOption.critic"
            rules="required|positive"
          />
          <custom-input
            :label="'Semicritico'"
            type="number"
            v-model="editLimitOption.semiCritic"
            rules="required|positive"
          />
          <div class="btn-container">
            <custom-button
              type="button"
              :variant="'text'"
              @click="isLimitModalOpen = false"
            >
              Cancelar
            </custom-button>

            <custom-button type="submit" :disabled="invalid">
              Aceptar
            </custom-button>
          </div>
        </form>
      </validation-observer>
    </modal>

    <div :style="{ display: 'none' }">
      <font-awesome-icon
        class="primary-color"
        :icon="icons.smileFace"
        slot="icon"
        ref="smileFace"
      />
      <font-awesome-icon
        class="secondary-color"
        :icon="icons.neutralFace"
        slot="icon"
        ref="neutralFace"
      />
      <font-awesome-icon
        class="nth-color"
        :icon="icons.sadFace"
        slot="icon"
        ref="sadFace"
      />
    </div>
  </div>
  <div class="loading-screen" v-else-if="loading"><loading-screen /></div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import MainChart from '../components/dashboard/MainChart.vue';
import DoughnutChartSatisfaction from '../components/dashboard/DoughnutChartSatisfaction.vue';
import DoughnutChartEngagement from '../components/dashboard/DoughnutChartEngagement.vue';
import DoughnutChartENPS from '../components/dashboard/DoughnutChartENPS.vue';
import FilterBox from '../components/dashboard/FilterBox.vue';
import CustomSelect from '../components/dashboard/CustomSelectChart.vue';
import ParticipationChart from '../components/dashboard/ParticipationChart.vue';
import PeriodSelect from '../components/dashboard/PeriodSelect.vue';
import ChartSelect from '../components/dashboard/ChartSelect.vue';
import LimitSelect from '../components/dashboard/LimitSelect.vue';
import Palette from '../components/dashboard/Palette.vue';
import TagBar from '../components/dashboard/TagBar.vue';
import Collapse from '../components/dashboard/Collapse.vue';
import Modal from '../components/Modal.vue';
import CustomButton from '../components/CustomButton.vue';
import CustomInput from '@/components/CustomInput.vue';
import LoadingScreen from '../components/LoadingScreen.vue';
import pptxgen from 'pptxgenjs';
//import print from "print-js";

import {
  faFileDownload,
  faSlidersH,
  faPlusCircle,
  faComment,
  faFilePowerpoint,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';

import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
//import download from "downloadjs";
export default {
  components: {
    MainChart,
    FilterBox,
    Modal,
    CustomSelect,
    DoughnutChartSatisfaction,
    DoughnutChartEngagement,
    DoughnutChartENPS,
    Palette,
    ParticipationChart,
    ChartSelect,
    TagBar,
    Collapse,
    CustomButton,
    LimitSelect,
    CustomInput,
    ValidationObserver,
    LoadingScreen,
    PeriodSelect,
  },
  data() {
    return {
      loading: false,
      participationData: {},
      chartData: {},
      chartType: 'squareBar',
      isModalOpen: false,
      filters: {},
      selectedDimensions: {},
      selectedParticipationCategory: '',
      selectedCategory: '',
      selectedDisplay: 'Análisis de Resultados',
      categories: [],
      dataComplete: true,
      isReportModalOpen: false,
      isLimitModalOpen: false,
      isOpenAnswersModalOpen: false,
      editLimitOption: {
        critic: 0,
        semiCritic: 0,
      },
      icons: {
        fileDownload: faFileDownload,
        filter: faSlidersH,
        plusCircle: faPlusCircle,
        openAnswers: faComment,
        powerpoint: faFilePowerpoint,
        confidential: faUserLock,
        sadFace: faFrown,
        neutralFace: faMeh,
        smileFace: faSmile,
      },
      satisfactionIndexOfCompany: [0, 0],
      totalWrittenAnswers: 0,
      filteredAnswersLength: 0,
      openAnswersData: {},
      engagementData: {},
      eNPSData: {},
    };
  },

  methods: {
    ...mapActions([
      'fetchCurrentSurvey',
      'fetchSections',
      'fetchAllAnswers',
      'getParticipationData',
      'fetchAllSurveys',
      'getDashboardData',
      'updateParticipationData',
      'updateSurveyColors',
      'updateSurveyLimits',
    ]),
    ...mapActions('users', ['fetchUsers']),

    ...mapActions('sections', ['fetchSections', 'fetchQuestions']),

    ...mapActions('scale', ['fetchScale']),

    ...mapActions('filters', ['fetchFilters']),

    ...mapMutations(['setCurrentSurvey']),

    openParticipation() {
      this.$refs.participationChart.isModalOpen = true;
    },

    setEditLimitOptionsModal() {
      if (this.selectedSurvey.limits) {
        this.editLimitOption.critic = this.selectedSurvey.limits[0] + '';
        this.editLimitOption.semiCritic = this.selectedSurvey.limits[1] + '';
      } else {
        this.editLimitOption.critic = 60;
        this.editLimitOption.semiCritic = 80;
      }
      this.isLimitModalOpen = true;
    },

    editSurveyLimits() {
      let limits = [
        Number(this.editLimitOption.critic),
        Number(this.editLimitOption.semiCritic),
      ];
      this.updateSurveyLimits(limits);
      this.selectedSurvey.limits = limits;
      this.isLimitModalOpen = false;
    },

    rgbToHex(r, g, b) {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },

    randomColor(r, g, b) {
      var maxMin = this.getMaxMin(r);
      var rN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      maxMin = this.getMaxMin(g);
      var gN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      maxMin = this.getMaxMin(b);
      var bN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      return this.rgbToHex(rN, gN, bN);
    },
    getMaxMin(value) {
      var max = value + 30 < 255 ? value + 30 : 255;
      var min = value + 80 < 255 ? value + 80 : 255;
      return [max, min];
    },
    generateColors() {
      if (!this.selectedSurvey.colors) {
        let tempColors = ['#15b195', '#A4D6C9'];
        for (let i = 0; i < this.selectedSurvey.scale.length; i++) {
          tempColors.push(this.randomColor(104, 208, 158));
        }
        this.updateSurveyColors(tempColors);
        this.selectedSurvey.colors = tempColors;
      }
    },
    async changeSurvey(survey) {
      this.loading = true;
      this.dataComplete = false;
      await this.setCurrentSurvey(survey);
      await this.fetchSections();
      await this.fetchScale();
      await this.fetchFilters();
      await this.fetchQuestions();
      await this.fetchUsers();
      // await this.fetchAllAnswers();
      this.setCategories();
      if (
        !['Dimension', ...this.categories.map((cat) => cat.name)].includes(
          this.selectedCategory,
        )
      ) {
        alert(
          'El periodo ' +
            this.selectedSurvey.name +
            ' no tiene el filtro ' +
            this.selectedCategory,
        );
        this.selectedCategory = 'Dimension';
      }

      this.dataComplete = true;
      await this.setData();
      this.loading = false;
    },
    changeColor(color, index) {
      let colorsCopy = [...this.selectedSurvey.colors];
      colorsCopy[index] = color;
      this.updateSurveyColors(colorsCopy);
      this.selectedSurvey.colors = colorsCopy;
    },
    async handleEditFilter(filters) {
      this.filters = { ...filters.selectedFilters };
      this.selectedDimensions = { ...filters.selectedDimensions };
      this.selectedCategory = filters.selectedCategory;
      this.isModalOpen = false;
      this.chartType = 'squareBar';
      await this.setDashboardData();
    },

    setCategories() {
      this.categories = [
        {
          id: 'question',
          name: 'Pregunta',
          options: this.selectedSurvey.questions,
        },
        ...this.cat.sort((x, y) =>
          x.orgHierarchy === y.orgHierarchy
            ? x.hasParent === y.hasParent
              ? 0
              : x.hasParent
              ? 1
              : -1
            : x.orgHierarchy
            ? -1
            : 1,
        ),
      ];
    },

    async setDashboardData() {
      if (!this.loading) this.loading = true;
      try {
        let dashboardData = await this.getDashboardData({
          surveyId: this.selectedSurvey.id,
          selectedDisplay: this.selectedDisplay,
          categories: this.categories,
          selectedCategory: this.selectedCategory,
          filters: this.filters,
          selectedDimensions: this.selectedDimensions,
        });
        this.chartData = dashboardData.chartData;
        this.satisfactionIndexOfCompany =
          dashboardData.companySatisfactionIndex;
        this.totalWrittenAnswers = dashboardData.totalWrittenAnswers;
        this.filteredAnswersLength = dashboardData.filteredAnswersLength;
        this.openAnswersData = dashboardData.openAnswersData;
        this.eNPSData = dashboardData.eNPSData;
        this.engagementData = dashboardData.engagementData;
      } catch (error) {
        console.log('Error getDashboardData cloud function: ' + error);
      }
      this.loading = false;
    },

    // async setParticipationData() {
    //   if (!this.loading) this.loading = true;
    //   try {
    //     this.participationData = await this.getParticipationData({
    //       surveyId: this.selectedSurvey.id,
    //       categories: [...this.cat].filter(cat => cat.orgHierarchy)
    //     });
    //   } catch (error) {
    //     console.log("Error getParticipationData cloud function: " + error);
    //   }
    // },

    async setData() {
      if (!this.loading) this.loading = true;
      try {
        let result = await Promise.all([
          this.getParticipationData({
            surveyId: this.selectedSurvey.id,
            categories: [
              this.cat.find(
                ({ id }) => id === this.selectedParticipationCategory,
              ),
            ],
          }),
          this.getDashboardData({
            surveyId: this.selectedSurvey.id,
            selectedDisplay: this.selectedDisplay,
            categories: this.categories,
            selectedCategory: this.selectedCategory,
            filters: this.filters,
            selectedDimensions: this.selectedDimensions,
          }),
        ]);
        this.participationData = result[0];
        let dashboardData = result[1];

        //activar para actualizar participation data
        // this.participationData = await this.getParticipationData({
        //   surveyId: this.selectedSurvey.id,
        //   categories: [...this.cat].filter((cat) => cat.orgHierarchy),
        // });

        // let dashboardData = await this.getDashboardData({
        //   surveyId: this.selectedSurvey.id,
        //   selectedDisplay: this.selectedDisplay,
        //   categories: this.categories,
        //   selectedCategory: this.selectedCategory,
        //   filters: this.filters,
        //   selectedDimensions: this.selectedDimensions,
        // });
        this.chartData = dashboardData.chartData;
        this.satisfactionIndexOfCompany =
          dashboardData.companySatisfactionIndex;
        this.totalWrittenAnswers = dashboardData.totalWrittenAnswers;
        this.filteredAnswersLength = dashboardData.filteredAnswersLength;
        this.openAnswersData = dashboardData.openAnswersData;
        this.eNPSData = dashboardData.eNPSData;
        this.engagementData = dashboardData.engagementData;
      } catch (error) {
        console.log('Error getDashboardData cloud function: ' + error);
      }
      this.loading = false;
    },

    downloadPPT() {
      let pptx = new pptxgen();
      let slide = pptx.addSlide();

      //5.62 tamaño ppt
      slide.addText('Informe de Resultados', {
        x: 0,
        y: 2.61,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 32,
      });

      let text = '';
      Object.keys(this.filters).forEach((key) => {
        text += ' / ' + key + ': ';
        this.filters[key].forEach((filter, index) => {
          text += filter + (index == this.filters[key].length - 1 ? '' : ', ');
        });
      });

      if (Object.keys(this.selectedDimensions).length) {
        text += ' / Dimension: ';
        Object.keys(this.selectedDimensions).forEach((key, index) => {
          text +=
            this.selectedDimensions[key] +
            (index == Object.keys(this.selectedDimensions).length - 1
              ? ''
              : ', ');
        });
        text += ' / ';
      }

      slide.addText(text, {
        x: 0,
        y: 3.1,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 14,
      });
      slide = pptx.addSlide();
      let mainChartData = [];

      this.chartData.legend.forEach((label, index) => {
        let labels = Object.keys(this.chartData.dataset).sort((a, b) =>
          parseInt(this.chartData.dataset[a][0]) >
          parseInt(this.chartData.dataset[b][0])
            ? -1
            : parseInt(this.chartData.dataset[b][0]) >
              parseInt(this.chartData.dataset[a][0])
            ? 1
            : 0,
        );
        let values = [];
        labels.forEach((label) =>
          values.push(this.chartData.dataset[label][index] / 100),
        );
        mainChartData.push({
          name: label,
          labels: labels,
          values: values,
        });
      });

      slide.addText([{ text: text }], {
        x: 0,
        y: 1,
        w: '100%',
        h: 0.1,
        fontSize: 8,
        align: 'center',
      });
      slide.addChart(
        this.chartType == 'line' ? pptx.ChartType.line : pptx.ChartType.bar,
        mainChartData,
        {
          x: 1,
          y: 1.2,
          w: 8,
          h: 4.3,
          catAxisLabelColor: '000000',
          catAxisLabelFontSize: 8,
          catAxisOrientation: 'minMax',
          valAxisLabelFontSize: 8,
          dataLabelFontSize: 8,
          legendFontSize: 8,
          titleFontSize: 10,
          chartColors: this.selectedSurvey.colors,
          dataLabelPosition: 't',
          dataLabelFormatCode: '#%',
          valAxisLabelFormatCode: '#%',
          showValue: true,
          showTitle: true,
          showLegend: true,
          legendPos: 't',
          valAxisMaxVal: 1,
          title: this.selectedDisplay + ': ' + this.selectedCategory,
        },
      );
      let border = { pt: '2', color: '15B195' };

      slide.addTable(
        [
          [
            {
              text: this.participation[0] + '%',
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                bold: true,
              },
            },
          ],
          [
            {
              text: this.participation[1] + ' respondidos',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                fontSize: 8,
              },
            },
          ],
        ],
        {
          x: 2.5,
          y: 0.3,
          w: 1.5,
          color: '15B195',
          align: 'center',
          border: { pt: '2', color: '15B195' },
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[0] };
      slide.addTable(
        [
          [
            {
              text: 'Fortaleza',
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                fontSize: 8,
              },
            },
          ],
          [
            {
              text: this.satisfactionIndexOfCompany[0] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 4.2,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[0],
          align: 'left',
          border: { pt: '2', color: this.selectedSurvey.colors[0] },
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[1] };
      slide.addTable(
        [
          [
            {
              text: 'Oportunidad',
              options: {
                border: [border, border, null, border],
                fontSize: 8,
                margin: [0.12, 0.12, 0, 0.12],
              },
            },
          ],
          [
            {
              text: this.satisfactionIndexOfCompany[1] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 5.9,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[1],
          align: 'left',
        },
      );

      const generalDoughnutData = [
        {
          name: 'Resultado General',
          labels: ['Fortaleza', 'Oportunidad'],
          values: this.satisfactionIndexOfCompany,
        },
      ];

      slide.addChart(pptx.charts.DOUGHNUT, generalDoughnutData, {
        x: 5.1,
        y: 0.3,
        w: 0.57,
        h: 0.57,
        chartColors: [this.selectedSurvey.colors[0], 'F5F6FA'],
        dataLabelColor: 'FFFFFF',
        dataLabelFontSize: 14,
        holeSize: 80,
        legendPos: 'b',
        showPercent: false,
        showLegend: false,
        showTitle: false,
        dataLabelPosition: 'ctr',
        titleFontFace: 'Helvetica Neue',
        titleFontSize: 10,
        title: 'Resultado General',
      });

      slide.addChart(pptx.charts.DOUGHNUT, generalDoughnutData, {
        x: 6.8,
        y: 0.3,
        w: 0.57,
        h: 0.57,
        chartColors: ['F5F6FA', this.selectedSurvey.colors[1]],
        dataLabelColor: 'FFFFFF',
        dataLabelFontSize: 14,
        holeSize: 80,
        legendPos: 'b',
        showPercent: false,
        showLegend: false,
        showTitle: false,
        dataLabelPosition: 'ctr',
        titleFontFace: 'Helvetica Neue',
        titleFontSize: 10,
        title: 'Resultado General',
      });

      slide = pptx.addSlide();

      const eNPSDoughnutData = [
        {
          name: 'Resultado General',
          labels: ['Detractores', 'Pasivos', 'Promotores'],
          values: this.eNPSData.eNPSSatisfactionIndex,
        },
      ];

      let svgElement = this.$refs.smileFace;
      svgElement.setAttribute('color', this.selectedSurvey.colors[0]);
      let s = new XMLSerializer().serializeToString(svgElement);
      let smileFaceImgData = 'data:image/svg+xml;base64,' + window.btoa(s);

      svgElement = this.$refs.neutralFace;
      svgElement.setAttribute('color', this.selectedSurvey.colors[1]);
      s = new XMLSerializer().serializeToString(svgElement);
      let neutralFaceImgData = 'data:image/svg+xml;base64,' + window.btoa(s);

      svgElement = this.$refs.sadFace;
      svgElement.setAttribute('color', '#FD7670');
      s = new XMLSerializer().serializeToString(svgElement);
      let sadFaceImgData = 'data:image/svg+xml;base64,' + window.btoa(s);

      slide.addText(
        this.selectedSurvey.questions.filter(
          (question) => question.sectionId === 'enps',
        )[0].text,
        {
          x: 1.7,
          y: 3.4,
          w: 6.6,
          h: 0.4,
          align: 'center',
          fontSize: 12,
        },
      );

      let gap = 0.33;
      for (let i = 0; i < 7; i++) {
        gap = 0.33 * i;
        slide.addText(i || '0', {
          x: 1.7 + gap + 0.3 * i,
          y: 3.8,
          w: 0.3,
          h: 0.3,
          fontSize: 12,
          color: 'FD7670',
          align: 'center',
        });
        slide.addImage({
          x: 1.7 + gap + 0.3 * i,
          y: 4.1,
          w: 0.3,
          h: 0.3,
          data: sadFaceImgData,
        });
        slide.addText(this.eNPSData.eNPSPerAnswer[i] + ' Votos', {
          x: 1.55 + gap + 0.3 * i,
          y: 4.45,
          w: 0.6,
          h: 0.3,
          fontSize: 10,
          color: '818891',
          align: 'center',
        });
      }
      for (let i = 7; i < 9; i++) {
        gap = 0.33 * i;
        slide.addText(i, {
          x: 1.7 + gap + 0.3 * i,
          y: 3.8,
          w: 0.3,
          h: 0.3,
          fontSize: 12,
          color: this.selectedSurvey.colors[1],
          align: 'center',
        });
        slide.addImage({
          x: 1.7 + gap + 0.3 * i,
          y: 4.1,
          w: 0.3,
          h: 0.3,
          data: neutralFaceImgData,
        });
        slide.addText(this.eNPSData.eNPSPerAnswer[i] + ' Votos', {
          x: 1.55 + gap + 0.3 * i,
          y: 4.45,
          w: 0.6,
          h: 0.3,
          fontSize: 10,
          color: '818891',
          align: 'center',
        });
      }

      for (let i = 9; i < 11; i++) {
        gap = 0.33 * i;
        slide.addText(i, {
          x: 1.7 + gap + 0.3 * i - (i == 10 ? 0.05 : 0),
          y: 3.8,
          w: 0.3 + (i == 10 ? 0.1 : 0),
          h: 0.3,
          fontSize: 12,
          color: this.selectedSurvey.colors[0],
          align: 'center',
        });
        slide.addImage({
          x: 1.7 + gap + 0.3 * i,
          y: 4.1,
          w: 0.3,
          h: 0.3,
          data: smileFaceImgData,
        });
        slide.addText(this.eNPSData.eNPSPerAnswer[i] + ' Votos', {
          x: 1.55 + gap + 0.3 * i,
          y: 4.45,
          w: 0.6,
          h: 0.3,
          fontSize: 10,
          color: '818891',
          align: 'center',
        });
      }

      border = { pt: '2', color: 'FD7670' };

      slide.addTable(
        [
          [
            {
              text: 'Detractores',
              options: {
                border: [border, null, null, null],
                margin: [0.08, 0, 0, 0],
              },
            },
          ],
        ],
        {
          x: 1.7,
          y: 4.9,
          w: 4.16,
          h: 0.3,
          fontSize: 10,
          color: 'FD7670',
          align: 'center',
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[1] };
      slide.addTable(
        [
          [
            {
              text: 'Pasivos',
              options: {
                border: [border, null, null, null],
                margin: [0.08, 0, 0, 0],
              },
            },
          ],
        ],
        {
          x: 1.7 + 4.16 + 0.11,
          y: 4.9,
          w: 1.11,
          h: 0.3,
          fontSize: 10,
          color: this.selectedSurvey.colors[1],
          align: 'center',
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[0] };
      slide.addTable(
        [
          [
            {
              text: 'Promotores',
              options: {
                border: [border, null, null, null],
                margin: [0.08, 0, 0, 0],
              },
            },
          ],
        ],
        {
          x: 1.7 + 4.16 + 1.11 + 0.11 * 2,
          y: 4.9,
          w: 1.11,
          h: 0.3,
          fontSize: 10,
          color: this.selectedSurvey.colors[0],
          align: 'center',
        },
      );

      slide.addChart(pptx.charts.DOUGHNUT, eNPSDoughnutData, {
        x: 1,
        y: 1,
        w: 2,
        h: 2,
        holeSize: 70,
        catAxisLabelColor: '000000',
        catAxisLabelFontSize: 8,
        catAxisOrientation: 'minMax',
        valAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        legendFontSize: 8,
        titleFontSize: 10,
        chartColors: [
          'FD7670',
          this.selectedSurvey.colors[1],
          this.selectedSurvey.colors[0],
        ],
        // dataLabelPosition: "t",
        // dataLabelFormatCode: "#%",
        // valAxisLabelFormatCode: "#%",
        showPercent: false,
        showValue: false,
        showTitle: false,
        showLegend: false,
        legendPos: 't',
        valAxisMaxVal: 1,
        title: 'eNPS',
      });

      slide.addText(this.eNPSData.eNPS, {
        x: 1.35,
        y: 1.65,
        h: 0.5,
        w: 1.3,
        align: 'center',
        fontSize: 42,
        color: this.selectedSurvey.colors[0],
      });
      slide.addText(this.eNPSMeasure, {
        x: 1.35,
        y: 2.1,
        h: 0.25,
        w: 1.3,
        align: 'center',
        fontSize: 12,
        color: this.selectedSurvey.colors[0],
      });

      slide.addText('Resultados eNPS', {
        x: 1.7,
        y: 0.4,
        w: 6.6,
        h: 0.4,
        align: 'center',
        fontSize: 16,
      });

      slide.addText('Detractores', {
        x: 3.5,
        y: 1.15,
        h: 0.25,
        w: 1.3,
        align: 'center',
        fontSize: 12,
        color: 'FD7670',
        line: { width: '2', color: 'FD7670' },
      });

      slide.addText(
        (
          (this.eNPSData.eNPSSatisfactionIndex[0] *
            this.filteredAnswersLength) /
          100
        ).toFixed(0),
        {
          x: 5.2,
          y: 1.15,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          color: 'FD7670',
          line: { width: '2', color: 'FD7670' },
        },
      );

      slide.addText(this.eNPSData.eNPSSatisfactionIndex[0].toFixed(0) + '%', {
        x: 6.3,
        y: 1.15,
        h: 0.25,
        w: 0.7,
        align: 'center',
        fontSize: 12,
        color: 'FD7670',
        line: { width: '2', color: 'FD7670' },
      });

      slide.addText('Pasivos', {
        x: 3.5,
        y: 1.15 + 0.25 + 0.466,
        h: 0.25,
        w: 1.3,
        align: 'center',
        fontSize: 12,
        color: this.selectedSurvey.colors[1],
        line: { width: '2', color: this.selectedSurvey.colors[1] },
      });

      slide.addText(
        (
          (this.eNPSData.eNPSSatisfactionIndex[1] *
            this.filteredAnswersLength) /
          100
        ).toFixed(0),
        {
          x: 5.2,
          y: 1.15 + 0.25 + 0.466,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          color: this.selectedSurvey.colors[1],
          line: { width: '2', color: this.selectedSurvey.colors[1] },
        },
      );

      slide.addText(this.eNPSData.eNPSSatisfactionIndex[1].toFixed(0) + '%', {
        x: 6.3,
        y: 1.15 + 0.25 + 0.466,
        h: 0.25,
        w: 0.7,
        align: 'center',
        fontSize: 12,
        color: this.selectedSurvey.colors[1],
        line: { width: '2', color: this.selectedSurvey.colors[1] },
      });

      slide.addText('Promotores', {
        x: 3.5,
        y: 1.15 + 0.25 * 2 + 0.466 * 2,
        h: 0.25,
        w: 1.3,
        align: 'center',
        fontSize: 12,
        color: this.selectedSurvey.colors[0],
        line: { width: '2', color: this.selectedSurvey.colors[0] },
      });

      slide.addText(
        (
          (this.eNPSData.eNPSSatisfactionIndex[2] *
            this.filteredAnswersLength) /
          100
        ).toFixed(0),
        {
          x: 5.2,
          y: 1.15 + 0.25 * 2 + 0.466 * 2,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          color: this.selectedSurvey.colors[0],
          line: { width: '2', color: this.selectedSurvey.colors[0] },
        },
      );

      slide.addText(this.eNPSData.eNPSSatisfactionIndex[2].toFixed(0) + '%', {
        x: 6.3,
        y: 1.15 + 0.25 * 2 + 0.466 * 2,
        h: 0.25,
        w: 0.7,
        align: 'center',
        fontSize: 12,
        color: this.selectedSurvey.colors[0],
        line: { width: '2', color: this.selectedSurvey.colors[0] },
      });

      slide.addText('Total', {
        x: 7.4,
        y: 1.15 + 0.45,
        h: 0.25,
        w: 0.7,
        align: 'center',
        fontSize: 12,
        line: { width: '2', color: '000000' },
      });

      slide.addText(this.filteredAnswersLength, {
        x: 7.4,
        y: 1.15 + 0.25 + 0.45 + 0.2,
        h: 0.25,
        w: 0.7,
        align: 'center',
        fontSize: 12,
        color: '818891',
        line: { width: '2', color: '818891' },
      });

      slide = pptx.addSlide();

      let labels = [];
      let values = [];
      this.selectedSurvey.questions
        .filter((question) => question.isEngagement)
        .forEach((question) => {
          values.push(
            this.engagementData.satisfactionIndexByTitle.dataset[question.text],
          );
          labels.push(question.title);
        });

      border = { pt: '2', color: this.selectedSurvey.colors[0] };
      slide.addTable(
        [
          [
            {
              text: labels[0],
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                fontSize: 8,
              },
            },
          ],
          [
            {
              text: values[0][0] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 1.7,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[0],
          align: 'left',
          border: { pt: '2', color: this.selectedSurvey.colors[0] },
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[1] };
      slide.addTable(
        [
          [
            {
              text: labels[1],
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                fontSize: 8,
              },
            },
          ],
          [
            {
              text: values[1][0] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 3.4,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[1],
          align: 'left',
          border: { pt: '2', color: this.selectedSurvey.colors[1] },
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[0] };
      slide.addTable(
        [
          [
            {
              text: labels[2],
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                fontSize: 8,
              },
            },
          ],
          [
            {
              text: values[2][0] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 5.1,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[0],
          align: 'left',
          border: { pt: '2', color: this.selectedSurvey.colors[0] },
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[1] };
      slide.addTable(
        [
          [
            {
              text: labels[3],
              options: {
                border: [border, border, null, border],
                fontSize: 8,
                margin: [0.12, 0.12, 0, 0.12],
              },
            },
          ],
          [
            {
              text: values[3][0] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 6.8,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[1],
          align: 'left',
        },
      );

      const engagementData = [
        {
          name: 'Resultado General',
          labels: ['Fortaleza', 'Oportunidad'],
          values: this.engagementData.satisfactionIndex,
        },
      ];

      slide.addChart(pptx.charts.DOUGHNUT, engagementData, {
        x: 1,
        y: 1.1,
        w: 8,
        h: 4.3,
        holeSize: 70,
        catAxisLabelColor: '000000',
        catAxisLabelFontSize: 8,
        catAxisOrientation: 'minMax',
        valAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        legendFontSize: 8,
        titleFontSize: 10,
        chartColors: this.selectedSurvey.colors,
        // dataLabelPosition: "t",
        // dataLabelFormatCode: "#%",
        // valAxisLabelFormatCode: "#%",
        showPercent: false,
        showValue: false,
        showTitle: true,
        showLegend: true,
        legendPos: 't',
        valAxisMaxVal: 1,
        title: 'Engagement',
      });

      slide.addText(this.engagementData.satisfactionIndex[0] + '%', {
        x: 4.35,
        y: 3.3,
        h: 0.5,
        w: 1.3,
        align: 'center',
        fontSize: 42,
        color: this.selectedSurvey.colors[0],
      });
      pptx.writeFile({ fileName: 'dashboard.pptx' });
    },
  },

  async mounted() {
    this.loading = true;
    try {
      await this.fetchUsers();
      await this.fetchCurrentSurvey(this.company.id);
      await this.fetchSections();
      await this.fetchScale();
      await this.fetchFilters();
      await this.fetchQuestions();
      // await this.fetchAllAnswers(); //
      await this.fetchAllSurveys(this.company.id);
    } catch (err) {
      console.log('error fetching');
    }

    this.generateColors();
    await this.setCategories();
    this.selectedCategory = 'Dimension';
    this.selectedParticipationCategory = this.cat[0].id;
    await this.setData();

    //this.selectedSurvey = this.surveys.find((survey) => survey.active);

    // let data = {};
    // Object.keys(this.participationData).forEach((dt) => {
    //   data[dt] = [
    //     this.participationData[dt].filteredUsers +
    //       this.participationData[dt].participationWrittenAnswers,
    //     this.participationData[dt].participation,
    //   ];
    // });
    // this.updateParticipationData(data);
    // console.log(this.selectedSurvey.participationData);

    //check duplicates answers
    // let data = [];
    // this.filteredUsers.forEach((user) => {
    //   data.push(user.id);
    // });
    // let answersTemp = this.answers.filter(
    //   (answer) => !data.includes(answer.userId)
    // );
    // console.log(answersTemp);
    // let ids = [];
    // let answersTemp = [];
    // this.answers.forEach((answer) => {
    //   if (!ids.includes(answer.userId)) {
    //     ids.push(answer.userId);
    //     answersTemp.push(answer);
    //   } else {
    //     console.log(answer);
    //     console.log(answersTemp.filter((aw) => aw.userId == answer.userId)[0]);
    //   }
    // });

    // si un progreso no se actualizo bien
    // let res = {};

    // let userIds = [];
    // this.answers.forEach((answer) => {
    //   if (!userIds.includes(answer.userId))
    //     this.cat.forEach((category) => {
    //       if (!res[answer.features[category.name]]) {
    //         res[answer.features[category.name]] = {};
    //         res[answer.features[category.name]].answers = 0;
    //         res[answer.features[category.name]].users = 0;
    //       }
    //       res[answer.features[category.name]].answers += 1;
    //     });
    //   userIds.push(answer.userId);
    // });

    // this.users.users.forEach((user) => {
    //   this.cat.forEach((category) => {
    //     if (user.progress === 1) {
    //       if (!res[user.filters[category.name]]) {
    //         res[user.filters[category.name]] = {};
    //         res[user.filters[category.name]].users = 0;
    //       }
    //       res[user.filters[category.name]].users += 1;
    //     }
    //   });
    // });

    // Object.keys(res)
    //   .filter((r) => res[r].answers !== res[r].users)
    //   .forEach((f) =>
    //     console.log(f + " " + res[f].answers + " / " + res[f].users)
    //   );
    // console.log(res);

    // console.log(
    //   this.answers.filter(
    //     (answer) =>
    //       answer.features.Sede === "EL BROCAL" &&
    //       answer.features["Rango de Edad"] === "35 - 44 años" &&
    //       answer.features["Cargo"] === "MAESTRO CARGADOR DE EXPLOSIVOS" &&
    //       answer.features["Tiempo Servicio"] === "3 – 5 años" &&
    //       answer.features["Generación"] === "Generación X"
    //   )
    // );
  },

  async beforeDestroy() {
    let survey = this.surveys.filter((survey) => survey.current);
    await this.changeSurvey(survey[0]);
  },

  computed: {
    ...mapState(['company', 'surveys', 'survey']),
    ...mapState(['users', 'answers']),

    ...mapState({
      cat: (state) => {
        return [...state.filters.filters];
      },
    }),

    eNPSMeasure() {
      if (
        parseFloat(this.eNPSData.eNPS) >= -100 &&
        parseFloat(this.eNPSData.eNPS) <= -10
      )
        return 'Criticos';
      else if (
        parseFloat(this.eNPSData.eNPS) > -10 &&
        parseFloat(this.eNPSData.eNPS) <= 0
      )
        return 'Alertas';
      else if (
        parseFloat(this.eNPSData.eNPS) > 0 &&
        parseFloat(this.eNPSData.eNPS) <= 10
      )
        return 'Promedio';
      else if (
        parseFloat(this.eNPSData.eNPS) > 10 &&
        parseFloat(this.eNPSData.eNPS) <= 30
      )
        return 'Superior';
      else return 'Excepcional';
    },

    isConfidential() {
      return this.filteredAnswersLength < (this.selectedSurvey.minRequire || 4);
    },

    filteredUsers() {
      let users = JSON.parse(JSON.stringify(this.users.users));
      for (let category in this.filters) {
        if (category !== 'Pregunta') {
          users = users.filter((user) =>
            this.filters[category].includes(user.filters[category]),
          );
        }
      }
      return users;
    },

    participation() {
      return [
        this.filteredUsers.length + this.totalWrittenAnswers === 0
          ? 0
          : (
              (this.filteredAnswersLength /
                (this.filteredUsers.length + this.totalWrittenAnswers)) *
              100
            ).toFixed(0),
        this.filteredAnswersLength,
      ];
    },

    ...mapState({
      selectedSurvey: (state) => {
        return {
          ...state.survey,
          // answers: [...state.answers],
          sections: state.sections.sections,
          questions: state.sections.questions,
          scale: state.scale.scale,
        };
      },
    }),

    dataStyles() {
      return {
        '--primary-selected-color': this.selectedSurvey.colors[0],
        '--secondary-selected-color': this.selectedSurvey.colors[1],
        '--nth2-selected-color': this.selectedSurvey.colors[2],
        '--nth3-selected-color': this.selectedSurvey.colors[3],

        '--options-length': this.cat.reduce(
          (acc, val) => (acc += val.options.length),
          0,
        ),
        '--category-length': this.cat.length,
      };
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.survey.active) next('/');
      else next();
    });
  },
};
</script>

<style scoped>
.dashboard-container > * {
  background-color: white;
}

h5 {
  margin: 0;
}

.dashboard-container h3 {
  color: var(--fontColor3);
}

.dashboard {
  padding: var(--lengthMd2) var(--lengthLg1) 0 var(--lengthLg1);
}

/* CONTAINER */
.dashboard-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: 'tools tools tools' 'tag tag tag' 'participation engagement ENPS' 'doughnut bar bar' 'doughnut bar bar';
  grid-template-rows: 9% 3% 31% 17% 30%;
  grid-template-columns: 24% 50% 24%;
  column-gap: 1%;
  row-gap: 2%;
  font-size: 14px;
}

.dashboard-container.confidential {
  grid-template-areas: 'tools tools tools' 'tag tag tag' 'confidential confidential confidential' 'confidential confidential confidential' 'confidential confidential confidential';
}

/* TOOLS */
.tools-container {
  grid-area: tools;
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding: 0 1em;
}

.tools-buttons {
  display: flex;
  align-items: flex-end;
}

.tools-buttons > * {
  margin: 0 0.8em;
}
/* DOUGHNUT */

.ENPS-chart-container {
  grid-area: ENPS;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

/* DOUGHNUT */

.doughnut-chart-container {
  grid-area: doughnut;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

/* DOUGHNUT */

.engagement-chart-container {
  grid-area: engagement;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid 3px #f7f7fc; */
}

/* PARTICIPATION CONTAINER */
.participation-container {
  grid-area: participation;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
  position: relative;
}

/* BAR CONTAINER */
.main-chart-container {
  padding: 1em 1em 0.2em 1em;
  grid-area: bar;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  height: 100%;
  border: solid 3px #f7f7fc;
}

.main-chart-container > div:nth-child(2) {
  flex-grow: 1;
  padding: 0.3em 0.3em 0 0.3em;
}

.main-chart-display h2 {
  margin-right: 1em;
}

.main-chart-container h3 {
  font-size: 1.2em;
}

.main-chart-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-chart-text > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 1.3em;
  margin-right: 2%;
}

.display-btn {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

.display-btn svg {
  height: 100%;
  width: 100%;
  color: var(--gray-color1);
}

.main-chart {
  height: 82%;
}

/* TAGS CONTAINER */
.tags-container {
  padding-left: 1em;
  grid-area: tag;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tag {
  margin-right: var(--lengthSm2);
  margin-bottom: var(--lengthSm2);
  margin-top: var(--lengthSm2);
  padding: 0.4rem var(--lengthSm3);
  border-radius: var(--lengthMd3);
  background-color: #ebeef0;
  display: flex;
  align-items: center;
  width: max-content;
}

.surveyLimitsForm {
  display: flex;
  flex-flow: column;
  gap: 1.2em;
}
.surveyLimitsForm .btn-container {
  display: flex;
  justify-content: space-between;
}

.loading-screen {
  flex-grow: 1;
}

.empty-state-confidential {
  grid-area: confidential;
  height: 100%;
  width: 40%;
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
}

.empty-state-confidential svg {
  height: 7em;
  width: 7em;
}

.primary-color {
  background-color: var(--primary-selected-color);
}

.secondary-color {
  background-color: var(--secondary-selected-color);
}

.nth-color {
  background-color: #fd7670;
}

@page {
  size: Legal landscape;
  margin: 2.5% 0;
}

@media (max-height: 1000px) {
  .dashboard-container > div {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .dashboard-container > div {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .dashboard-container > div {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .dashboard-container > div {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .dashboard-container > div {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .dashboard-container > div {
    font-size: 8px;
  }
}
</style>
