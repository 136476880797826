<template>
  <div class="container">
    <div>
      <div>
        <h2>Engagement</h2>
        <div class="display-btn" @click="isReportModalOpen = true">
          <font-awesome-icon :icon="icons.plusCircle" slot="icon" />
        </div>
      </div>
      <div class="doughnut-chart__container" ref="chart" :style="sizeStyle">
        <doughnut-chart
          :chartData="dataCollection"
          :options="{
            tooltips: { enabled: false },
            legend: {
              display: false
            },
            responsive: true,
            cutoutPercentage: 80
          }"
        ></doughnut-chart>
        <div class="ppc-percents">
          <div class="pcc-percents-wrapper">
            <span>
              <span>{{ value ? value : satisfactionIndexOfCompany[0] }}</span>
              <span class="percent-symbol">%</span>
            </span>
            <span v-show="label" style="font-size: 1em">{{ label }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template
        v-for="(question, index) in engagementQuestions.sort((a, b) =>
          parseInt(satisfactionIndexByTitle.dataset[a.text][0]) >
          parseInt(satisfactionIndexByTitle.dataset[b.text][0])
            ? -1
            : parseInt(satisfactionIndexByTitle.dataset[b.text][0]) >
              parseInt(satisfactionIndexByTitle.dataset[a.text][0])
            ? 1
            : 0
        )"
      >
        <div :key="index" class="engagement-section">
          <h2>{{ question.title }}</h2>
          <h1 :class="index >= 2 ? 'primary-color' : 'secondary-color'">
            {{
              satisfactionIndexByTitle.dataset[question.text]
                ? satisfactionIndexByTitle.dataset[question.text][0]
                : 0
            }}%
          </h1>
        </div>
      </template>
    </div>

    <modal :isOpen="isReportModalOpen" @close="isReportModalOpen = false">
      <div class="file-buttons">
        <custom-button
          variant="button"
          download="reporte-compromiso.csv"
          :href="downloadLink"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button variant="button" @click="downloadAsPDF">
          PDF
          <font-awesome-icon :icon="icons.pdf" slot="icon" />
        </custom-button>
      </div>
      <div ref="printMe">
        <template slot="title">Reporte</template>
        <div class="report-title">
          <div>
            <h1>Reporte Clima Laboral</h1>
            <h1>Compromiso</h1>
          </div>
        </div>
        <div class="report-filters">
          <h1>Filtros seleccionados:</h1>
          <div>
            <template v-for="key in Object.keys(filters)"
              >/
              <p :key="key + 'p'">{{ " " + key + ":" }}</p>
              <div :key="key + 'div'">
                <p v-for="(filter, index) in filters[key]" :key="filter">
                  {{ filter + (index == filters[key].length - 1 ? "" : ",") }}
                </p>
              </div>
            </template>
            <template v-if="Object.keys(selectedDimensions).length">
              /
              <p>{{ " " + "Dimension" + ":" }}</p>
              <div>
                <p
                  v-for="(key, index) in Object.keys(selectedDimensions)"
                  :key="key"
                >
                  {{
                    selectedDimensions[key] +
                      (index == Object.keys(selectedDimensions).length - 1
                        ? ""
                        : ",")
                  }}
                </p>
              </div>
              /
            </template>
          </div>
        </div>

        <div class="report-data-table">
          <div class="header row">
            <template>
              <span><p>Participación #</p></span>
              <span><p>Participación %</p></span>
              <span><p>Resultado General</p></span>
              <span><p>Dimensiones</p></span>
              <span><p>Preguntas</p></span>
            </template>
          </div>

          <div class="row">
            <span
              ><p>{{ participation[1] }}</p></span
            >
            <span
              ><p>{{ participation[0] }}%</p></span
            >
            <span
              :class="
                satisfactionIndex[0] <= limits[0]
                  ? 'limit-bad'
                  : satisfactionIndex[0] <= limits[1]
                  ? 'limit-medium'
                  : 'limit-good'
              "
            >
              <p>{{ satisfactionIndex[0] }}%</p>
            </span>
            <span>
              <p>{{ 1 }}</p>
            </span>
            <span>
              <p>{{ 4 }}</p>
            </span>
          </div>
          <div><span></span></div>
        </div>

        <div class="report-table">
          <div class="header row">
            <template>
              <span>Titulo</span>
              <span>Pregunta</span>
              <span><p>Fortaleza</p></span>
              <span><p>Oportunidad</p></span>
            </template>
          </div>
          <div
            class="row"
            v-for="question in engagementQuestions"
            :key="question.id"
          >
            <span>
              <p>{{ question.title }}</p>
            </span>
            <span>
              <p>{{ question.text }}</p>
            </span>
            <span
              :class="
                (satisfactionIndexByTitle.dataset[question.text]
                  ? satisfactionIndexByTitle.dataset[question.text][0]
                  : 0) <= limits[0]
                  ? 'limit-bad'
                  : (satisfactionIndexByTitle.dataset[question.text]
                      ? satisfactionIndexByTitle.dataset[question.text][0]
                      : 0) <= limits[1]
                  ? 'limit-medium'
                  : 'limit-good'
              "
              >{{
                satisfactionIndexByTitle.dataset[question.text]
                  ? satisfactionIndexByTitle.dataset[question.text][0]
                  : 0
              }}%</span
            >
            <span
              >{{
                satisfactionIndexByTitle.dataset[question.text]
                  ? satisfactionIndexByTitle.dataset[question.text][1]
                  : 0
              }}%</span
            >
          </div>
          <div><span></span></div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import jspdf from "jspdf";
import { mapState } from "vuex";
import DoughnutChart from "../charts/DoughnutChart.vue";
import Modal from "../../components/Modal.vue";
import CustomButton from "../../components/CustomButton.vue";
import {
  faFileDownload,
  faPlusCircle,
  faFileExcel,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    DoughnutChart,
    Modal,
    CustomButton
  },
  data() {
    return {
      size: null,
      sizeStyle: "",
      isReportModalOpen: false,
      icons: {
        fileDownload: faFileDownload,
        plusCircle: faPlusCircle,
        excel: faFileExcel,
        pdf: faFilePdf
      }
    };
  },

  props: {
    colors: {
      type: Array,
      required: true
    },
    scale: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    label: {
      required: false
    },
    value: {
      required: false,
      default: false
    },
    filters: {
      type: Object,
      required: true
    },
    participation: {
      type: Array,
      required: true
    },
    satisfactionIndex: {
      type: Array,
      required: true
    },
    limits: {
      type: Array,
      required: true
    },
    selectedDimensions: {
      type: Object,
      required: true
    },
    satisfactionIndexOfCompany: {
      required: true
    },
    satisfactionIndexByTitle: {
      type: Object,
      required: true
    }
  },

  methods: {
    downloadAsPDF() {
      const el = this.$refs.printMe;
      this.$html2canvas(el).then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jspdf();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(
          imgData,
          "PNG",
          (pdf.internal.pageSize.getWidth() - pdfWidth) / 2,
          10,
          pdfWidth,
          pdfHeight
        );
        pdf.save("reporte-compromiso.pdf");
      });
    },

    CSVtoArray(text) {
      var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // Return NULL if input string is not well formed CSV string.
      if (!re_valid.test(text)) return null;
      var a = []; // Initialize array to receive values.
      text.replace(
        re_value, // "Walk" the string using replace with callback.
        function(m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return ""; // Return empty string.
        }
      );
      // Handle special case of empty last value.
      if (/,\s*$/.test(text)) a.push("");
      return a.join(" ");
    },

    getReportCSV() {
      let csv = [];
      csv.push([
        "Participación #",
        "Participación %",
        "Resultado General",
        "Dimensiones",
        "Preguntas"
      ]);

      csv.push(
        "\n" + this.participation[1],
        this.participation[0] + "%",
        this.satisfactionIndex[0] + "%",
        1,
        4
      );

      csv.push(["\n \n" + "Titulo", "Fortaleza", "Oportunidad"]);
      let temp = [];
      this.engagementQuestions.forEach(question => {
        temp.push(
          "\n" + this.CSVtoArray(question.title),
          (this.satisfactionIndexByTitle.dataset[question.text]
            ? this.satisfactionIndexByTitle.dataset[question.text][0]
            : 0) + "%",
          (this.satisfactionIndexByTitle.dataset[question.text]
            ? this.satisfactionIndexByTitle.dataset[question.text][1]
            : 0) + "%"
        );
      });
      csv.push(temp);

      return csv;
    }
  },

  mounted() {
    this.size = this.$refs.chart.clientHeight;
    this.sizeStyle = { width: this.size + "px" };
  },

  computed: {
    ...mapState("sections", ["questions"]),

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    engagementQuestions() {
      return this.questions.filter(question => question.isEngagement);
    },
    dataCollection() {
      return {
        labels: ["1", "2", "3", "4"],
        datasets: [
          {
            label: "dataset",
            data: this.satisfactionIndexOfCompany,
            backgroundColor: [...this.colors],
            circumference: 80,
            hoverOffset: 4
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

.container > div:first-child {
  width: 50%;
}

.display-btn {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

.display-btn svg {
  height: 100%;
  width: 100%;
  color: var(--gray-color1);
}

.container h2,
.container h3 {
  font-size: 1.2em;
}

h1 {
  font-size: 1.4em;
}

.content {
  display: grid;
  grid-template-columns: 50% 50%;
  flex-grow: 1;
  flex-flow: column;
  border: solid 3px #f7f7fc;
  text-align: center;
  margin-left: 2%;
}

.content > div {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.content > div:first-child {
  border-right: solid 3px #f7f7fc;
  border-bottom: solid 3px #f7f7fc;
}

.content > div:nth-child(2) {
  border-bottom: solid 3px #f7f7fc;
}

.content > div:nth-child(3) {
  border-right: solid 3px #f7f7fc;
}

.container > div:first-child {
  padding: 1em;
  display: flex;
  flex-flow: column;
  gap: 1em;
  align-items: center;
  border: solid 3px #f7f7fc;
}

.container > div:first-child > div {
  height: 1.7em;
  width: 100%;
  display: flex;
  align-items: center;
}

.doughnut-chart__container > div:first-child {
  width: 85%;
  margin: 0 auto;
}

.container > div:first-child h2 {
  flex-grow: 1;
}

.doughnut-chart__container {
  position: relative;
  flex-grow: 1;
}

.ppc-percents {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 82%;
  height: 82%;
  background: transparent;
  text-align: center;
  display: table;
}
.ppc-percents div > span {
  display: block;
  font-size: 2.6em;
  font-weight: bold;
  width: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  color: var(--primary-selected-color);
}

.ppc-percents div > span > span {
  font-size: 1em;
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.primary-color {
  color: var(--primary-selected-color);
}

.secondary-color {
  color: var(--secondary-selected-color);
}

.ppc-percents .percent-symbol {
  font-size: 0.65em;
  width: fit-content;
}

.report-table {
  margin: auto;
}

.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-right: 1px solid var(--grayColor2);
  border-left: 1px solid var(--grayColor2);
  border-bottom: 1px solid var(--grayColor2);
  font-size: 0.8rem;
}

.header.row {
  border-top: 1px solid var(--grayColor2);
  border-top-left-radius: var(--lengthSm2);
  border-top-right-radius: var(--lengthSm2);
  color: white;
  color: var(--fontColor3);
  font-weight: var(--semi-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.row span {
  padding: var(--lengthSm3);
  font-weight: var(--medium);
}

.row span:not(:last-child) {
  border-right: 1px solid var(--grayColor2);
}

.report-filters {
  text-align: center;
  margin-bottom: var(--lengthMd2);
  margin-top: var(--lengthSm2);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.report-filters > div {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: center;
  min-width: 95%;
  max-width: 95%;
  flex-wrap: wrap;
}

.report-filters h1 {
  font-size: 1rem;
  font-weight: bold;
}

.report-filters > div > div > p {
  font-weight: bold;
}

.report-filters p {
  font-size: 0.85rem;
}

.report-filters div {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.report-data-table .row {
  grid-template-columns: repeat(5, 1fr);
}

.limit-bad {
  color: #fd7670;
}

.limit-medium {
  color: #f3c319;
}

.limit-good {
  color: #15b195;
}

.report-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.report-title > div {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.report-title .logo-container {
  height: 1.8em;
  position: absolute;
  right: 1em;
  top: 0;
}

.report-title * {
  font-size: 1.2rem;
}

/* LOGO CONTAINER */
.logo-container {
  grid-area: logo;
  margin: 0.5em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo-container svg {
  height: 100%;
}

.logo-container .cls-1 {
  fill: var(--primary-selected-color);
}
.logo-container .cls-2,
.logo-container .cls-3 {
  fill: #3d3f3e;
}
.logo-container .cls-2 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

.report-table .row > span {
  display: flex;
  align-items: center;
}

.report-data-table .row > span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.report-table .row > span:not(:first-child):not(:nth-child(2)) {
  display: flex;
  justify-content: center;
  text-align: center;
}

.file-buttons {
  display: flex;
  gap: 1em;
}

@media (max-height: 1000px) {
  .container {
    font-size: 16px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}
</style>
