<template>
  <div class="container">
    <div class="content">
      <div>
        <h2>Participación</h2>
        <div v-if="categories" class="display-btn" @click="isModalOpen = true">
          <font-awesome-icon :icon="icons.plusCircle" slot="icon" />
        </div>
      </div>
      <div class="number-container">
        <h1 class="primary-color">
          <span>{{ totalParticipation }}</span>
          <span class="percent-symbol">%</span>
        </h1>
        <div>
          <div class="bar">
            <span
              class="fill"
              :style="{
                width: totalParticipation + '%',
              }"
            ></span>
          </div>
          <h3>{{ participation }} respondidos</h3>
        </div>
      </div>
    </div>

    <modal
      v-if="categories && isModalOpen"
      :isOpen="isModalOpen"
      @close="isModalOpen = false"
    >
      <div class="file-buttons">
        <custom-button
          variant="button"
          download="reporte-participación.csv"
          :href="downloadLink"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button variant="button" @click="downloadAsPDF">
          PDF
          <font-awesome-icon :icon="icons.pdf" slot="icon" />
        </custom-button>
      </div>

      <div ref="printMe">
        <template slot="title">Participación</template>

        <div class="participation-title">
          <h1>Participación</h1>
        </div>

        <div
          v-if="Object.keys(participationData).length"
          class="participation-table"
        >
          <div class="header row">
            <span>
              <custom-select
                :options="
                  categories.map((category) => {
                    return { value: category.name, id: category.id };
                  })
                "
                @change="(e) => $emit('change', e)"
                :selected="selectedCategory"
              >
              </custom-select>
            </span>
            <span><p>Participación #</p></span>
            <span><p>Participación %</p></span>
            <span><p>Total</p></span>
          </div>
          <div
            class="row"
            v-for="option in category.options"
            :key="category.id + option.name"
          >
            <span>
              <p>{{ option.name }}</p>
            </span>
            <!-- <span>{{
                participationData[option.name]
                  ? participationData[option.name][1]
                  : 0
              }}</span> -->
            <!-- <span
                >{{
                  participationData[option.name]
                    ? (
                        (participationData[option.name][1] /
                          participationData[option.name][0]) *
                          100 || 0
                      ).toFixed(0)
                    : 0
                }}%
              </span> -->
            <span>{{ participationData[option.name].participation }}</span>
            <span>
              {{
                (
                  (participationData[option.name].participation /
                    (participationData[option.name].filteredUsers +
                      participationData[option.name]
                        .participationWrittenAnswers)) *
                    100 || 0
                ).toFixed(0)
              }}%
            </span>
            <span>
              {{
                participationData[option.name].filteredUsers +
                  participationData[option.name].participationWrittenAnswers
              }}
            </span>
          </div>
          <div><span></span></div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import jspdf from 'jspdf';
import { mapState } from 'vuex';
import Modal from '../Modal.vue';
import CustomButton from '../CustomButton.vue';
import CustomSelect from '../../components/CustomSelect.vue';
//import Papa from "papaparse";
import {
  faFileDownload,
  faPlusCircle,
  faMale,
  faFemale,
  faFileExcel,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';

export default {
  components: {
    Modal,
    CustomButton,
    CustomSelect,
  },
  data() {
    return {
      countParticipationPercent: 0,
      isModalOpen: false,
      downloadLink: '',
      icons: {
        fileDownload: faFileDownload,
        plusCircle: faPlusCircle,
        male: faMale,
        female: faFemale,
        excel: faFileExcel,
        pdf: faFilePdf,
      },
    };
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
    totalWrittenAnswers: {
      required: true,
    },
    participation: {
      required: true,
    },
    totalParticipation: {
      required: true,
    },
    surveyId: {
      required: true,
    },
    participationData: {
      require: true,
    },
    selectedCategory: {
      required: true,
    },
  },

  methods: {
    downloadAsPDF() {
      const el = this.$refs.printMe;
      this.$html2canvas(el).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jspdf('p', 'pt', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const pdf2 = new jspdf('p', 'pt', [
          pdf.internal.pageSize.getWidth(),
          pdfHeight + 20 > pdf.internal.pageSize.getHeight()
            ? pdfHeight + 20
            : pdf.internal.pageSize.getHeight(),
        ]);
        pdf2.addImage(
          imgData,
          'PNG',
          (pdf2.internal.pageSize.getWidth() - pdfWidth) / 2,
          10,
          pdfWidth,
          pdfHeight,
        );
        pdf2.save('reporte-participación.pdf');
      });
    },
    CSVtoArray(text) {
      // var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      // var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // // Return NULL if input string is not well formed CSV string.
      // if (!re_valid.test(text)) return null;
      // var a = []; // Initialize array to receive values.
      // text.replace(
      //   re_value, // "Walk" the string using replace with callback.
      //   function(m0, m1, m2, m3) {
      //     // Remove backslash from \' in single quoted values.
      //     if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      //     // Remove backslash from \" in double quoted values.
      //     else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      //     else if (m3 !== undefined) a.push(m3);
      //     return ""; // Return empty string.
      //   }
      // );
      // // Handle special case of empty last value.
      // if (/,\s*$/.test(text)) a.push("");
      // return a.join(" ");
      return text.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '');
    },

    getParticipationCSV() {
      let csv = [
        ['Organización', 'Participación #', 'Participación %', 'Total'],
      ];
      let temp = [];
      this.category.options.forEach((option) => {
        // temp.push(
        //   "\n" + this.CSVtoArray(option.name),
        //   this.participationData[option.name]
        //     ? this.participationData[option.name][1]
        //     : 0,
        //   (this.participationData[option.name]
        //     ? (this.participationData[option.name][1] /
        //         this.participationData[option.name][0]) *
        //         100 || 0
        //     : 0
        //   ).toFixed(0) + "%"
        // );
        temp.push(
          '\n' + this.CSVtoArray(option.name),
          this.participationData[option.name].participation,
          (
            (this.participationData[option.name].participation /
              (this.participationData[option.name].filteredUsers +
                this.participationData[option.name]
                  .participationWrittenAnswers)) *
              100 || 0
          ).toFixed(0) + '%',
          this.participationData[option.name].filteredUsers +
            this.participationData[option.name].participationWrittenAnswers,
        );
      });
      csv.push(temp);
      return csv;
    },

    setCSVLink(csvFileData) {
      var csvContent = '\uFEFF' + csvFileData;
      var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      var url = URL.createObjectURL(blob);
      this.downloadLink = url;
    },
  },

  async mounted() {
    try {
      this.setCSVLink(this.getParticipationCSV());
    } catch (error) {
      console.log('Error al crear el csv');
      console.log(error);
    }
  },

  computed: {
    ...mapState(['users']),
    ...mapState({
      categories: (state) => {
        return state.filters.filters;
      },
    }),
    category() {
      return this.categories.find(({ id }) => id === this.selectedCategory);
    },
    filteredUsers() {
      let users = JSON.parse(JSON.stringify(this.users.users));
      for (let category in this.filters) {
        if (category !== 'Pregunta') {
          users = users.filter((user) =>
            this.filters[category].includes(user.filters[category]),
          );
        }
      }
      return users;
    },
    // participationData() {
    //   let participationData = {};
    //   this.categories.forEach((category) => {
    //     category.options.forEach((option) => {
    //       participationData[option.name] = {
    //         participation: 0,
    //         filteredUsers: 0,
    //         participationWrittenAnswers: 0,
    //       };
    //     });
    //   });
    //   this.surveyAnswers.forEach((answer) => {
    //     this.categories.forEach((category) => {
    //       if (
    //         answer.features[category.name] &&
    //         answer.features[category.name] != "N/A"
    //       ) {
    //         participationData[
    //           answer.features[category.name]
    //         ].participation += 1;
    //         participationData[
    //           answer.features[category.name]
    //         ].participationWrittenAnswers += answer.isWrittenAnswer ? 1 : 0;
    //       }
    //     });
    //   });
    //   this.users.users.forEach((user) => {
    //     this.categories.forEach((category) => {
    //       participationData[user.filters[category.name]].filteredUsers += 1;
    //     });
    //   });
    //   return participationData;
    // },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  position: relative;
  font-size: 14px;
}

h3 {
  color: var(--fontColor3);
}

h2 {
  font-size: 1.2em;
}

.container h3 {
  font-size: 1em;
  text-align: left;
}

.content {
  display: flex;
  width: 100%;
  flex-flow: column;
}

.number-container h1 {
  font-size: 4.5em;
}

.number-container h1 span:not(.percent-symbol) {
  font-size: 1em;
}

.content > div > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.content > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content > div:nth-child(2) {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.primary-color {
  color: var(--primary-selected-color);
}

.secondary-color {
  color: var(--secondary-selected-color);
}

.display-btn {
  height: 1.4rem;
  width: 1.4rem;
  cursor: pointer;
}

.display-btn svg {
  height: 100%;
  width: 100%;
  color: var(--gray-color1);
}

.participation-table {
  margin: auto;
  margin-top: var(--lengthLg1);
}

.row span {
  display: flex;
  align-items: center;
}

.row {
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr);
  border-right: 1px solid var(--grayColor2);
  border-left: 1px solid var(--grayColor2);
  border-bottom: 1px solid var(--grayColor2);
  font-size: 0.8rem;
}

.header.row {
  border-top: 1px solid var(--grayColor2);
  border-top-left-radius: var(--lengthSm2);
  border-top-right-radius: var(--lengthSm2);
  color: white;
  color: var(--fontColor3);
  font-weight: var(--semi-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.row span {
  padding: var(--lengthSm3);
  font-weight: var(--medium);
}

.row span:not(:last-child) {
  border-right: 1px solid var(--grayColor2);
}

.percent-symbol {
  font-size: 0.65em;
  width: fit-content;
}

.number-container > div {
  display: flex;
  flex-flow: column;
  gap: 0.5em;
}
.bar {
  width: 100%;
  height: 0.5em;
  border-radius: 10px;
  background-color: #f5f6fa;
  position: relative;
  overflow: hidden;
}

.fill {
  position: absolute;
  height: 100%;
  background-color: var(--primary-selected-color);
}

.participation-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.participation-title > div {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.participation-title .logo-container {
  height: 1.8em;
  position: absolute;
  right: 1em;
  top: 0;
}

.participation-title * {
  font-size: 1.2rem;
}

h1 {
  font-size: 1.4em;
}

.row > span:not(:first-child) {
  justify-content: center;
}

/* LOGO CONTAINER */
.logo-container {
  grid-area: logo;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo-container svg {
  height: 100%;
}

.logo-container .cls-1 {
  fill: var(--primary-selected-color);
}
.logo-container .cls-2,
.logo-container .cls-3 {
  fill: #3d3f3e;
}
.logo-container .cls-2 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

.file-buttons {
  display: flex;
  gap: 1em;
}

@media (max-height: 1000px) {
  .container {
    font-size: 16px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}

@media print {
  * {
    font-size: 14px;
  }
}
</style>
