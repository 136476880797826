<template>
  <div class="container">
    <div>
      <h2>Distribución eNPS</h2>
      <div class="display-btn" @click="isReportModalOpen = true">
        <font-awesome-icon :icon="icons.plusCircle" slot="icon" />
      </div>
    </div>
    <div>
      <div class="doughnut-chart__container" ref="chart" :style="sizeStyle">
        <doughnut-chart
          :style="sizeStyle"
          :chartData="dataCollection"
          :options="{
            tooltips: { enabled: false },
            legend: {
              display: false
            },
            responsive: true,
            cutoutPercentage: 80,
            maintainAspectRatio: false
          }"
        ></doughnut-chart>
        <div class="ppc-percents">
          <div class="pcc-percents-wrapper">
            <span>
              <span>{{ eNPS }}</span>
            </span>
            <span style="font-size: .8em">{{ eNPSMeasure }}</span>
          </div>
        </div>
      </div>
      <div class="content">
        <div>
          <h1 class="primary-color">
            {{ eNPSSatisfactionIndex[2].toFixed(0) + "" }}%
          </h1>
          <h3>Promotores</h3>
        </div>
        <div>
          <h1 class="secondary-color">
            {{ eNPSSatisfactionIndex[1].toFixed(0) + "" }}%
          </h1>
          <h3>Pasivos</h3>
        </div>
        <div>
          <h1 class="nth-color">
            {{ eNPSSatisfactionIndex[0].toFixed(0) + "" }}%
          </h1>
          <h3>Detractores</h3>
        </div>
      </div>
    </div>

    <modal :isOpen="isReportModalOpen" @close="isReportModalOpen = false">
      <div class="file-buttons">
        <custom-button
          variant="button"
          download="reporte-eNPS.csv"
          :href="downloadLink"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button variant="button" @click="downloadAsPDF">
          PDF
          <font-awesome-icon :icon="icons.pdf" slot="icon" />
        </custom-button>
      </div>
      <div ref="printMe" class="report__container">
        <template slot="title">Reporte</template>
        <div class="report-title">
          <div>
            <h1>Reporte Clima Laboral</h1>
            <h1>eNPS</h1>
          </div>
        </div>

        <div class="report-filters">
          <h1>Filtros seleccionados:</h1>
          <div>
            <template v-for="key in Object.keys(filters)"
              >/
              <p :key="key + 'p'">{{ " " + key + ":" }}</p>
              <div :key="key + 'div'">
                <p v-for="(filter, index) in filters[key]" :key="filter">
                  {{ filter + (index == filters[key].length - 1 ? "" : ",") }}
                </p>
              </div>
            </template>
            <template v-if="Object.keys(selectedDimensions).length">
              /
              <p>{{ " " + "Dimension" + ":" }}</p>
              <div>
                <p
                  v-for="(key, index) in Object.keys(selectedDimensions)"
                  :key="key"
                >
                  {{
                    selectedDimensions[key] +
                      (index == Object.keys(selectedDimensions).length - 1
                        ? ""
                        : ",")
                  }}
                </p>
              </div>
              /
            </template>
          </div>
        </div>

        <div class="report-data-table">
          <div class="header row">
            <template>
              <span><p>Participación #</p></span>
              <span><p>Participación %</p></span>
              <span><p>Dimensiones</p></span>
              <span><p>Preguntas</p></span>
            </template>
          </div>

          <div class="row">
            <span
              ><p>{{ participation[1] }}</p></span
            >
            <span
              ><p>{{ participation[0] }}%</p></span
            >
            <span>
              <p>{{ 1 }}</p>
            </span>
            <span>
              <p>{{ 1 }}</p>
            </span>
          </div>
        </div>

        <div class="report-question">
          <h2>{{ question }}</h2>
        </div>
        <div class="report-eNPS">
          <div class="flex column">
            <div class="flex">
              <div class="flex column" v-for="index in 7" :key="index - 1">
                <p class="nth-color">
                  {{ index - 1 }}
                </p>
                <font-awesome-icon
                  class="nth-color"
                  :icon="icons.sadFace"
                  slot="icon"
                  ref="svg"
                />
                <div>
                  <p>{{ eNPSPerAnswer[index - 1] }}</p>
                  <p>Votos</p>
                </div>
              </div>
            </div>
            <div>
              <span class="nth-color"></span>
              <p class="nth-color">
                {{ eNPSSatisfactionIndex[0].toFixed(0) }}%
              </p>
              <p class="nth-color">Detractores</p>
            </div>
          </div>
          <div class="flex column">
            <div class="flex">
              <div class="flex column" v-for="index in 2" :key="index - 1 + 6">
                <p class="secondary-color">
                  {{ index + 6 }}
                </p>
                <font-awesome-icon
                  class="secondary-color"
                  :icon="icons.neutralFace"
                  slot="icon"
                  ref="svg"
                />
                <div>
                  <p>{{ eNPSPerAnswer[index + 6] }}</p>
                  <p>Votos</p>
                </div>
              </div>
            </div>
            <div>
              <span class="secondary-color"></span>
              <p class="secondary-color">
                {{ eNPSSatisfactionIndex[1].toFixed(0) }}%
              </p>
              <p class="secondary-color">Pasivos</p>
            </div>
          </div>
          <div class="flex column">
            <div class="flex">
              <div class="flex column" v-for="index in 2" :key="index + 8">
                <p class="primary-color">
                  {{ index + 8 }}
                </p>
                <font-awesome-icon
                  class="primary-color"
                  :icon="icons.smileFace"
                  slot="icon"
                  ref="svg"
                />
                <div>
                  <p>{{ eNPSPerAnswer[index + 8] }}</p>
                  <p>Votos</p>
                </div>
              </div>
            </div>
            <div>
              <span class="primary-color"></span>
              <p class="primary-color">
                {{ eNPSSatisfactionIndex[2].toFixed(0) }}%
              </p>
              <p class="primary-color">Promotores</p>
            </div>
          </div>
        </div>

        <div class="report-chart__container">
          <h1>
            <span>eNPS =</span>&nbsp;<span class="primary-color"
              >(% Promotores</span
            >&nbsp;<span> - </span>&nbsp;<span class="nth-color"
              >% Detractores)</span
            >&nbsp;<span> / Total</span>
          </h1>
          <div>
            <div
              class="doughnut-chart__container"
              ref="chart"
              :style="sizeStyle"
            >
              <doughnut-chart
                :chartData="dataCollection"
                :options="{
                  tooltips: { enabled: false },
                  legend: {
                    display: false
                  },
                  responsive: true,
                  cutoutPercentage: 80
                }"
              ></doughnut-chart>
              <div class="ppc-percents">
                <div class="pcc-percents-wrapper">
                  <span
                    ><span>{{ eNPS }}</span></span
                  >
                  <span style="font-size: .8em">{{ eNPSMeasure }}</span>
                </div>
              </div>
            </div>

            <div class="report-enps names">
              <h1>
                Detractores
              </h1>
              <h1>
                Pasivos
              </h1>
              <h1>
                Promotores
              </h1>
            </div>

            <div class="report-enps number">
              <h1>
                {{
                  (
                    (eNPSSatisfactionIndex[0] * filteredAnswersLength) /
                    100
                  ).toFixed(0)
                }}
              </h1>
              <h1>
                {{
                  (
                    (eNPSSatisfactionIndex[1] * filteredAnswersLength) /
                    100
                  ).toFixed(0)
                }}
              </h1>
              <h1>
                {{
                  (
                    (eNPSSatisfactionIndex[2] * filteredAnswersLength) /
                    100
                  ).toFixed(0)
                }}
              </h1>
            </div>

            <div class="report-enps percent">
              <h1>{{ eNPSSatisfactionIndex[0].toFixed(0) + "" }}%</h1>
              <h1>{{ eNPSSatisfactionIndex[1].toFixed(0) + "" }}%</h1>
              <h1>{{ eNPSSatisfactionIndex[2].toFixed(0) + "" }}%</h1>
            </div>

            <div class="report-enps total">
              <h1>Total</h1>
              <h1>{{ filteredAnswersLength }}</h1>
            </div>
          </div>
        </div>

        <div class="report-enps-info">
          <div>
            <h2>Promotores</h2>
            <p>
              Colaboradores que evalúan a la Empresa por arriba de sus
              expectativas y se encuentran muy dispuestos a recomendarla
            </p>

            <p>-100 y -10: Criticos</p>
            <p>-10 y 0: Alertas</p>
          </div>
          <div>
            <h2>Pasivos</h2>
            <p>
              Colaboradores que evalúan a la Empresa a la altura de sus
              expectativas y se muestran indiferentes a recomendarla
            </p>

            <p>+0 y +10: Promedio</p>
            <p>+10 y +30: Superior</p>
          </div>
          <div>
            <h2>Detractores</h2>
            <p>
              Colaboradores que evalúan a la Empresa por abajo de sus
              expectativas y no se encuentran dispuestos a recomendarla
            </p>

            <p>Sobre +30: Excepcional</p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import jspdf from "jspdf";
import DoughnutChart from "../charts/DoughnutChart.vue";
import Modal from "../../components/Modal.vue";
import CustomButton from "../../components/CustomButton.vue";
import {
  faFileDownload,
  faPlusCircle,
  faFileExcel,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";

import { faFrown, faMeh, faSmile } from "@fortawesome/free-regular-svg-icons";

export default {
  components: {
    DoughnutChart,
    Modal,
    CustomButton
  },
  data() {
    return {
      size: null,
      sizeStyle: "",
      isReportModalOpen: false,
      question: "",
      icons: {
        fileDownload: faFileDownload,
        plusCircle: faPlusCircle,
        sadFace: faFrown,
        neutralFace: faMeh,
        smileFace: faSmile,
        excel: faFileExcel,
        pdf: faFilePdf
      }
    };
  },

  props: {
    colors: {
      type: Array,
      required: true
    },
    value: {
      required: false,
      default: false
    },
    filters: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      require: true
    },
    participation: {
      type: Array,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    selectedDimensions: {
      type: Object,
      required: true
    },
    eNPS: {
      required: true
    },
    eNPSPerAnswer: {
      required: true
    },
    eNPSSatisfactionIndex: {
      required: true
    },
    filteredAnswersLength: {
      required: true
    }
  },

  methods: {
    downloadAsPDF() {
      const svgElements = this.$refs.svg;
      let colors = this.colors;
      svgElements.forEach(function(item) {
        let color = "white";
        if (item.classList[0] === "primary-color") color = colors[0];
        else if (item.classList[0] === "secondary-color") color = colors[1];
        else if (item.classList[0] === "nth-color") color = "#fd7670";
        item.setAttribute("width", item.getBoundingClientRect().width);
        item.setAttribute("height", item.getBoundingClientRect().height);
        item.setAttribute("color", color);
        item.style.width = null;
        item.style.height = null;
      });

      const el = this.$refs.printMe;
      this.$html2canvas(el).then(canvas => {
        const imgData = canvas.toDataURL("image/jpg");
        const pdf = new jspdf();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(
          imgData,
          "PNG",
          (pdf.internal.pageSize.getWidth() - pdfWidth) / 2,
          10,
          pdfWidth,
          pdfHeight
        );
        pdf.save("reporte-eNPS.pdf");
      });
    },

    CSVtoArray(text) {
      var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // Return NULL if input string is not well formed CSV string.
      if (!re_valid.test(text)) return null;
      var a = []; // Initialize array to receive values.
      text.replace(
        re_value, // "Walk" the string using replace with callback.
        function(m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return ""; // Return empty string.
        }
      );
      // Handle special case of empty last value.
      if (/,\s*$/.test(text)) a.push("");
      return a.join(" ");
    },

    getReportCSV() {
      let csv = [];
      csv.push([
        "Participación #",
        "Participación %",
        "Dimensiones",
        "Preguntas"
      ]);

      csv.push("\n" + this.participation[1], this.participation[0] + "%", 1, 1);

      csv.push(["\n \n" + "Grupo", "Votos"]);
      let temp = [];
      this.eNPSPerAnswer.forEach((answer, index) => {
        temp.push("\n" + index, answer);
      });
      csv.push(temp);

      return csv;
    }
  },

  mounted() {
    this.size = this.$refs.chart.clientHeight;
    this.sizeStyle = { height: this.size + "px", width: this.size + "px" };
    this.question = this.questions.filter(
      question => question.sectionId === "enps"
    )[0].text;
  },

  computed: {
    eNPSMeasure() {
      if (parseFloat(this.eNPS) >= -100 && parseFloat(this.eNPS) <= -10)
        return "Criticos";
      else if (parseFloat(this.eNPS) > -10 && parseFloat(this.eNPS) <= 0)
        return "Alertas";
      else if (parseFloat(this.eNPS) > 0 && parseFloat(this.eNPS) <= 10)
        return "Promedio";
      else if (parseFloat(this.eNPS) > 10 && parseFloat(this.eNPS) <= 30)
        return "Superior";
      else return "Excepcional";
    },

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    dataCollection() {
      return {
        labels: ["Satisfacción", "Insatisfacción"],
        datasets: [
          {
            label: "dataset",
            data: this.eNPSSatisfactionIndex,
            backgroundColor: ["#FD7670", this.colors[1], this.colors[0]],
            circumference: 80,
            hoverOffset: 4
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  gap: 1em;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

h1 {
  font-size: 1.1em;
}

h3 {
  color: var(--fontColor3);
  font-size: 1em;
}

.container > div:first-child,
.container > div:nth-child(2) {
  height: 1.7em;
}

.container > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container > div:nth-child(2) {
  flex-grow: 1;
  display: flex;
  gap: 10%;
}

.container h2 {
  font-size: 1.2em;
}

.display-btn {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

.display-btn svg {
  height: 100%;
  width: 100%;
  color: var(--gray-color1);
}

.content {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.doughnut-chart__container {
  position: relative;
  height: 85%;
  margin: auto 0;
}

.primary-color,
.primary-color svg {
  color: var(--primary-selected-color);
  fill: var(--primary-selected-color);
}

.secondary-color,
.secondary-color svg {
  color: var(--secondary-selected-color);
  fill: var(--secondary-selected-color);
}

.nth-color,
.nth-color svg {
  color: #fd7670;
  fill: #fd7670;
}

.doughnut-chart__container {
  position: relative;
}

.ppc-percents {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 82%;
  height: 82%;
  background: transparent;
  text-align: center;
  display: table;
}
.ppc-percents div > span {
  display: block;
  font-size: 2.6em;
  font-weight: bold;
  width: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  color: var(--primary-selected-color);
}

.ppc-percents div > span > span {
  font-size: 1em;
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.report-filters {
  text-align: center;
  margin-bottom: var(--lengthMd2);
  margin-top: var(--lengthSm2);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.report-filters > div {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: center;
  min-width: 95%;
  max-width: 95%;
  flex-wrap: wrap;
}

.report-filters > div > div > p {
  font-weight: bold;
}

.report-filters h1 {
  font-size: 1rem;
  font-weight: bold;
}

.report-filters div {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.report-eNPS {
  margin: 2rem 0 3rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  gap: 1.6%;
}

.report-eNPS > div:first-child {
  width: 61.6%;
}

.report-eNPS > div:nth-child(2),
.report-eNPS > div:nth-child(3) {
  width: 17.6%;
}

.report-eNPS > div > div {
  width: 100%;
}

.report-eNPS > div > div span {
  width: 94%;
  height: 0.2em;
}

.report-eNPS > div > div span.primary-color {
  background-color: var(--primary-selected-color);
}

.report-eNPS > div > div span.secondary-color {
  background-color: var(--secondary-selected-color);
}

.report-eNPS > div > div span.nth-color {
  background-color: #fd7670;
}

.report-eNPS > div > div > div {
  flex-grow: 1;
  gap: 0.5em;
}

.report-eNPS svg {
  height: 2.6rem;
  width: 2.6rem;
}

.report-eNPS .flex {
  display: flex;
  align-items: center;
}

.report-eNPS .column {
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.report-enps-info {
  margin-top: 3rem;
  padding: 1em;
  gap: 2rem;
  display: flex;
  justify-content: space-between;
}

.report-enps-info p {
  text-align: justify;
  text-justify: inter-word;
}

.report-enps-info p:nth-child(2) {
  margin-bottom: 1em;
}

.report-chart__container > div {
  display: flex;
  justify-content: space-around;
}

.report-chart__container > h1 {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.report-enps {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.report-enps.number h1 {
  border-radius: 100px;
  height: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-enps.total {
  justify-content: center;
  gap: 1em;
}

.report-enps.total h1:nth-child(2) {
  border-radius: 100px;
  height: 1.8em;
  width: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.report-enps.total h1:first-child {
  border: solid 3px black;
  color: black;
}

.report-enps.total h1:nth-child(2) {
  border: solid 3px rgb(129, 136, 145);
  color: rgb(129, 136, 145);
}

.report-chart__container > div h1 {
  padding: 0.2em 1em;
  border-radius: 6px;
  text-align: center;
}

.report-chart__container > div h1:first-child {
  border: solid 3px #fd7670;
  color: #fd7670;
}

.report-chart__container > div h1:nth-child(2) {
  border: solid 3px var(--secondary-selected-color);
  color: var(--secondary-selected-color);
}

.report-chart__container > div h1:nth-child(3) {
  border: solid 3px var(--primary-selected-color);
  color: var(--primary-selected-color);
}

.report-data-table {
  margin-bottom: 3rem;
}

.report-data-table .row {
  grid-template-columns: repeat(4, 1fr);
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-right: 1px solid var(--grayColor2);
  border-left: 1px solid var(--grayColor2);
  border-bottom: 1px solid var(--grayColor2);
  font-size: 0.8rem;
}

.header.row {
  border-top: 1px solid var(--grayColor2);
  border-top-left-radius: var(--lengthSm2);
  border-top-right-radius: var(--lengthSm2);
  color: white;
  color: var(--fontColor3);
  font-weight: var(--semi-bold);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.row span {
  padding: var(--lengthSm3);
  font-weight: var(--medium);
}

.row span:not(:last-child) {
  border-right: 1px solid var(--grayColor2);
}

.report-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.report-title > div {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.report__container .report-title * {
  font-size: 1.2rem;
}

.limit-bad {
  color: #fd7670;
}

.limit-medium {
  color: #f3c319;
}

.limit-good {
  color: #15b195;
}

.report-data-table .row {
  text-align: center;
}

.file-buttons {
  display: flex;
  gap: 1em;
}

/* LOGO CONTAINER */
.logo-container {
  grid-area: logo;
  margin: 0.5em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 1.8em;
  width: 5em;
  position: absolute;
  right: 1em;
  top: 0;
}

.logo-container svg {
  height: 100%;
  width: 100%;
}

.logo-container .cls-1 {
  fill: var(--primary-selected-color);
}
.logo-container .cls-2,
.logo-container .cls-3 {
  fill: #3d3f3e;
}
.logo-container .cls-2 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

.report__container h2 {
  font-size: 1rem;
}

.report__container h1 {
  font-size: 1rem;
}

.report__container p {
  font-size: 0.85rem;
}

@media (max-height: 1000px) {
  .container {
    font-size: 16px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  .doughnut-chart__container {
    overflow: hidden;
  }

  .doughnut-chart__container > div:first-child,
  .doughnut-chart__container > div:first-child * {
    max-height: 100%;
    min-height: 100%;
    height: 100%;
    max-width: 100%;
  }
}
</style>
